import { defineStore } from "pinia";

import type { IChallengeState, IChallengeActions, IGetChallengesResponseDto } from "./interface";

const useChallengesStore = defineStore<"challengesStore", IChallengeState, {}, IChallengeActions>(
    "challengesStore",
    {
        state: (): IChallengeState => ({
            challengeDtos: null,
            challengeDtosBaseTemplateId: null,
            nextToken: null,
            nextTokenBaseTemplateId: null,
        }),
        actions: {
            setChallengeDto(dto: IGetChallengesResponseDto) {
                this.challengeDtos = dto;
            },
            setChallengeDtoBaseTemplateId(dto: IGetChallengesResponseDto) {
                this.challengeDtosBaseTemplateId = dto;
            },
            setNextToken(nextToken: string) {
                this.nextToken = nextToken;
            },
            setNextTokenBaseTemplateId(nextToken: string) {
                this.nextTokenBaseTemplateId = nextToken;
            },
            resetState() {
                this.challengeDtos = null;
                this.challengeDtosBaseTemplateId = null;
                this.nextToken = null;
                this.nextTokenBaseTemplateId = null;
            },
        },
    },
);

export default useChallengesStore;
