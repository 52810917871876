import { defineStore } from "pinia";

import type {
    AcademyClassState,
    Teacher,
} from "@/adapter/common/stores/client/academy-class/interface";
import type { TableDataSource } from "@/adapter/common/interface";

const useAcademyClassStore = defineStore("academyClassStore", {
    state: (): AcademyClassState => ({
        assignedStudentsTableBody: [],
        totalStudentsTableBody: [],
        referenceTeachers: [],
        classState: {
            classId: "",
            assignedStudents: [],
            mainTeacherId: "",
            subTeacher: [],
            name: "",
            isDeletable: true,
            isModifiable: true,
        },
    }),
    actions: {
        setAssignedStudentsTableBody(assignedStudentsTableBody: TableDataSource[]) {
            this.assignedStudentsTableBody = assignedStudentsTableBody;
        },
        setTotalStudentsTableBody(totalStudentsTableBody: TableDataSource[]) {
            this.totalStudentsTableBody = totalStudentsTableBody;
        },
        setReferenceTeachers(referenceTeachers: Teacher[]) {
            this.referenceTeachers = referenceTeachers;
        },
        setAssignedStudents(assignedStudents: string[]) {
            this.classState.assignedStudents = assignedStudents;
        },
        setMainTeacherId(mainTeacherId: string) {
            this.classState.mainTeacherId = mainTeacherId;
        },
        setSubTeacher(subTeacher: string[]) {
            this.classState.subTeacher = subTeacher;
        },
        setName(name: string) {
            this.classState.name = name;
        },
        setClassId(classId: string) {
            this.classState.classId = classId;
        },
        setDeletable(isDeletable: boolean) {
            this.classState.isDeletable = isDeletable;
        },
        setModifiable(isModifiable: boolean) {
            this.classState.isModifiable = isModifiable;
        },
        resetClassState() {
            this.classState.assignedStudents = [];
            this.classState.mainTeacherId = "";
            this.classState.subTeacher = [];
            this.classState.name = "";
            this.classState.isDeletable = true;
            this.classState.isModifiable = true;
        },
        resetAllState() {
            this.assignedStudentsTableBody = [];
            this.totalStudentsTableBody = [];
            this.referenceTeachers = [];
            this.classState.assignedStudents = [];
            this.classState.mainTeacherId = "";
            this.classState.subTeacher = [];
            this.classState.name = "";
            this.classState.isDeletable = true;
            this.classState.isModifiable = true;
        },
    },
});

export default useAcademyClassStore;
