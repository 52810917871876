import { singleton } from "tsyringe";

@singleton()
export default class ListenMessageHandler {
    constructor() {}

    public handler = (popup: Window) => (event: MessageEvent) => {
        if (event.origin !== import.meta.env.VITE_COMMON_ACCOUNTS_URL) return;

        const parseData = JSON.parse(event.data);

        if (parseData.userDto) {
            console.log(parseData.userDto);
        }

        popup.close();
        window.removeEventListener("message", this.handler(popup));
    };

    public listen = (popup: Window) => {
        window.addEventListener("message", this.handler(popup));
    };
}
