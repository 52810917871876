import { defineStore } from "pinia";
import type {
    ISearchManagerState,
    IStudentStatus,
    ISearchResult,
    ISearchCategorizedResult,
} from "@/infrastructure/stores/server/students-record/interface";

import type { ResponseDtos } from "@/domain/common/interface/slc-application/dto/reponses/dtos";

const useStudentsRecordStore = defineStore("studentsRecordStore", {
    state: (): ISearchManagerState => ({
        academyStudentsDto: null,
        academyCandidatesDto: null,
        academyFormerStudentsDto: null,
        searchWord: "",
        status: "academyStudent",
        categorizedResult: {
            academyStudents: null,
            academyCandidates: null,
            formerStudents: null,
        },
        categorizedTokens: {
            academyStudent: [null],
            candidateStudent: [null],
            formerStudent: [null],
        },
    }),
    getters: {
        /** @description 상태에 따른 검색결과를 가져옵니다. */
        getSearchResult: (state) => {
            return state.categorizedResult[state.status];
        },

        /** @description 상태에 따른 nextToken 을 가져옵니다. */
        getToken: (state) => {
            return (index: string) => {
                // 첫 렌더링 시 nextToken 을 배열내에 넣어놓도록 설정한다. (다른곳에서)
                return state.categorizedTokens[state.status][index];
            };
        },

        getTokenArray: (state) => {
            return state.categorizedTokens[state.status];
        },

        getAllTotalCount: (state) => {
            return {
                academyStudent: state.academyStudentsDto?.totalCount,
                candidateStudent: state.academyCandidatesDto?.totalCount,
                formerStudent: state.academyFormerStudentsDto?.totalCount,
            };
        },

        getTotalCount: (state) => {
            switch (state.status) {
                case "academyStudent":
                    return state.academyStudentsDto?.totalCount;
                case "candidateStudent":
                    return state.academyCandidatesDto?.totalCount;
                case "formerStudent":
                    return state.academyFormerStudentsDto?.totalCount;
                default:
                    return null;
            }
        },
    },
    actions: {
        /**
         * @description
         * 검색어를 저장합니다.
         * 추후 cache 기능을 적용할 시 활용할 수 있습니다.
         *  */
        setSearchWord(searchWord: string) {
            this.searchWord = searchWord;
            // 검색어가 변경되면 token 을 초기화합니다.
            this.categorizedTokens = {
                academyStudent: [null],
                candidateStudent: [null],
                formerStudent: [null],
            };
        },

        /** @description server 에서 dto 를 저장합니다. */
        setAcademyStudentsDto(responseDto: ResponseDtos.GetAcademyStudents) {
            this.academyStudentsDto = responseDto;
        },

        /** @description server 에서 dto 를 저장합니다. */
        setAcademyCandidatesDto(responseDto: ResponseDtos.GetAcademyCandidateDto) {
            this.academyCandidatesDto = responseDto;
        },

        /** @description server 에서 dto 를 저장합니다. */
        setAcademyFormerStudentsDto(responseDto: ResponseDtos.GetFormerStudents) {
            this.academyFormerStudentsDto = responseDto;
        },

        /** @description 상태릂 변경합니다. */
        changeStatus(status: IStudentStatus) {
            this.status = status;
        },

        /**
         * @description
         * 검색 결과를 상태별로 저장합니다.
         * 이 부분은 api 의 응답에 따라 완전히 변경될 예정입니다. */
        setPartialSearchResult(status: IStudentStatus, searchResult: ISearchResult[]) {
            this.categorizedResult[status] = searchResult;
        },

        /** @description 전체 검색 결과를 저장합니다. */
        setSearchResult(result: ISearchCategorizedResult) {
            this.categorizedResult = result;
        },

        /** @description 데이터를 호출할 때 마다 nextToken 을 갱신해줍니다. */
        setToken(status: IStudentStatus, nextToken: string) {
            // 우선 해당 nextToken 이 있는지 확인합니다.
            if (this.categorizedTokens[status].includes(nextToken)) {
                return;
            }
            // 없다면 push 를 통해 저장합니다. (순서 중요)
            this.categorizedTokens[status].push(nextToken);
        },

        resetState() {
            this.academyStudentsDto = null;
            this.academyCandidatesDto = null;
            this.academyFormerStudentsDto = null;
            this.searchWord = "";
            this.status = "academyStudent";
            this.categorizedResult = {
                academyStudents: null,
                academyCandidates: null,
                formerStudents: null,
            };
            this.categorizedTokens = {
                academyStudent: [null],
                candidateStudent: [null],
                formerStudent: [null],
            };
        },
    },
});

export default useStudentsRecordStore;
