import { defineStore } from "pinia";

import type {
    ComponentPropsInModalSecondLayer,
    ModalSecondLayerState,
    ModalLayout,
    ModalInitialState,
} from "@/adapter/common/stores/client/modal-second-layer/interface";

const useModalSecondLayerStore = defineStore("modalSecondLayerStore", {
    state: (): ModalSecondLayerState<keyof ComponentPropsInModalSecondLayer, ModalLayout> => ({
        modalOpen: false,
        modalComponent: null,
        modalLayout: null,
        modalProps: null,
        modalInitialState: {} as ModalInitialState,
    }),
    actions: {
        onmountModal<T extends keyof ComponentPropsInModalSecondLayer>(
            component: T,
            props: ComponentPropsInModalSecondLayer[T],
        ) {
            this.modalComponent = component;
            this.modalProps = props ? { ...props } : null;
            this.modalLayout = props ? props.layout : null;
            this.modalOpen = true;
        },
        unmountModal() {
            this.modalOpen = false;
            this.modalComponent = null;
            this.modalLayout = null;
            this.modalProps = null;
        },
        setInitialState<T extends keyof ComponentPropsInModalSecondLayer>(
            component: T,
            initialState: ModalInitialState[T],
        ) {
            this.modalInitialState[component] = initialState;
        },
    },
});

export default useModalSecondLayerStore;
