import { defineStore } from "pinia";

import type {
    IPlannedCurriculumsState,
    ICurriculumDto,
    IPlannedCurriculumDto,
} from "@/infrastructure/stores/server/planned-curriculums/interface";

const usePlannedCurriculumsStore = defineStore("plannedCurriculumsStore", {
    state: (): IPlannedCurriculumsState => ({
        curriculumDtos: null,
        plannedCurriculum: null,
    }),
    actions: {
        setCurriculumDtos(curriculumDtos: ICurriculumDto[]) {
            this.curriculumDtos = curriculumDtos;
        },
        setPlannedCurriculum(plannedCurriculum: IPlannedCurriculumDto[]) {
            this.plannedCurriculum = plannedCurriculum;
        },
    },
});

export default usePlannedCurriculumsStore;
