import { container } from "tsyringe";

// 생각해보면 factory 내에서 store 를 생성하는것이 테스트에서 용이할지는 의문이 듭니다.
// store 하나하나 구현체로서 주입되는것이 아니라면 테스트에서 좀 더 어려울 수 있다는 판단입니다.
// 따라서 현재 stateRegister 내에서 모든 server State 를 따로 등록해주고
// 이에 대한 타입을 application 내에서 사용하도록 하겠습니다. (여기서도 정의합니다. 왜냐하면 UI 에서도 사용될 수 있으니)

// impl
import StoreManagerImpl from "@/infrastructure/libs/state-management";

// store
import useAuthStore from "@/infrastructure/stores/server/auth";
import useStudentStore from "@/infrastructure/stores/server/student";
import useCandidateStore from "@/infrastructure/stores/server/candidate";
import useStudentsRecordStore from "@/infrastructure/stores/server/students-record";
import useAttendanceRecordStore from "@/infrastructure/stores/server/attendance-record";
import useCurriculumMetadatasStore from "@/infrastructure/stores/server/curriculum-metadatas";
import usePlannedCurriculumsStore from "@/infrastructure/stores/server/planned-curriculums";
import useLocationsStore from "@/infrastructure/stores/server/location";
import useMiddleUnitsStore from "@/infrastructure/stores/server/middle-units";
import useTodayAttendanceRecordStore from "@/infrastructure/stores/server/today-attendance-record";
import usePendingRegistrationStudentStore from "@/infrastructure/stores/server/pending-registration-student";
import usePendingRegistrationRecordStore from "@/infrastructure/stores/server/pending-registrations-record";
import useAcademyClassesStore from "@/infrastructure/stores/server/academy-classes";
import useTeacherStore from "@/infrastructure/stores/server/teacher";
import useTodayAttendanceStudentStore from "@/infrastructure/stores/server/today-attendance-student";
import useChallengesTemplateStore from "@/infrastructure/stores/server/challenge-template";
import useChallengesStore from "@/infrastructure/stores/server/challenge";
import useStudentChallengesStore from "@/infrastructure/stores/server/student-challenges";
import useStudentSubmittedProblemsStore from "@/infrastructure/stores/server/student-submitted-problems";

// types
import type { AuthState } from "@/application/common/interface/state-manager/auth";
import type { CandidateManagerState } from "@/application/common/interface/state-manager/candidate";
import type { StudentManagerState } from "@/application/common/interface/state-manager/student";
import type { SearchManagerState } from "@/application/common/interface/state-manager/students-record";
import type { AttendanceRecordManagerState } from "@/application/common/interface/state-manager/attendance-record";
import type { CurriculumMetadatasState } from "@/application/common/interface/state-manager/curriculum-metadatas";
import type { PlannedCurriculumsState } from "@/application/common/interface/state-manager/planned-curriculums";
import type { LocationsManagerState } from "@/application/common/interface/state-manager/location";
import type { MiddleUnitsManagerState } from "@/application/common/interface/state-manager/middle-units";
import type { TodayAttendanceRecordState } from "@/application/common/interface/state-manager/today-attendance-record";
import type { PendingRegistrationStudentState } from "@/application/common/interface/state-manager/pending-registration-student";
import type { PendingRegistrationsRecordState } from "@/application/common/interface/state-manager/pending-registrations-record";
import type { AcademyClassesState } from "@/application/common/interface/state-manager/academy-classes";
import type { TeacherManagerState } from "@/application/common/interface/state-manager/teacher";
import type { TodayAttendanceStudentState } from "@/application/common/interface/state-manager/today-attendance-student";
import type { ChallengeTemplateState } from "@/application/common/interface/state-manager/challenge-template";
import type { ChallengeState } from "@/application/common/interface/state-manager/challenge";
import type { StudentChallengesState } from "@/application/common/interface/state-manager/student-challenges";
import type { StudentSubmittedProblemsState } from "@/application/common/interface/state-manager/student-submitted-problems";
import { ServerStateManager } from "@/infrastructure/stores/server/interface";

const stateRegisterInit = () => {
    container.register<ServerStateManager.AuthManager>("AuthManager", {
        useValue: new StoreManagerImpl<AuthState>(useAuthStore()),
    });
    container.register<ServerStateManager.CandidateManager>("CandidateManager", {
        useValue: new StoreManagerImpl<CandidateManagerState>(useCandidateStore()),
    });
    container.register<ServerStateManager.StudentManager>("StudentManager", {
        useValue: new StoreManagerImpl<StudentManagerState>(useStudentStore()),
    });
    container.register<ServerStateManager.StudentRecordManager>("StudentRecordManager", {
        useValue: new StoreManagerImpl<SearchManagerState>(useStudentsRecordStore()),
    });
    container.register<ServerStateManager.AttendanceRecordManager>("AttendanceRecordManager", {
        useValue: new StoreManagerImpl<AttendanceRecordManagerState>(useAttendanceRecordStore()),
    });
    container.register<ServerStateManager.CurriculumMetadatasManager>(
        "CurriculumMetadatasManager",
        { useValue: new StoreManagerImpl<CurriculumMetadatasState>(useCurriculumMetadatasStore()) },
    );
    container.register<ServerStateManager.PlannedCurriculumsManager>("PlannedCurriculumsManager", {
        useValue: new StoreManagerImpl<PlannedCurriculumsState>(usePlannedCurriculumsStore()),
    });
    container.register<ServerStateManager.LocationsManager>("LocationsManager", {
        useValue: new StoreManagerImpl<LocationsManagerState>(useLocationsStore()),
    });
    container.register<ServerStateManager.MiddleUnitsManager>("MiddleUnitsManager", {
        useValue: new StoreManagerImpl<MiddleUnitsManagerState>(useMiddleUnitsStore()),
    });
    container.register<ServerStateManager.TodayAttendanceRecordManager>(
        "TodayAttendanceRecordManager",
        {
            useValue: new StoreManagerImpl<TodayAttendanceRecordState>(
                useTodayAttendanceRecordStore(),
            ),
        },
    );
    container.register<ServerStateManager.PendingRegistrationStudentManager>(
        "PendingRegistrationStudentManager",
        {
            useValue: new StoreManagerImpl<PendingRegistrationStudentState>(
                usePendingRegistrationStudentStore(),
            ),
        },
    );
    container.register<ServerStateManager.PendingRegistrationsRecordManager>(
        "PendingRegistrationsRecordManager",
        {
            useValue: new StoreManagerImpl<PendingRegistrationsRecordState>(
                usePendingRegistrationRecordStore(),
            ),
        },
    );
    container.register<ServerStateManager.AcademyClassesManager>("AcademyClassesManager", {
        useValue: new StoreManagerImpl<AcademyClassesState>(useAcademyClassesStore()),
    });
    container.register<ServerStateManager.TeacherManager>("TeacherManager", {
        useValue: new StoreManagerImpl<TeacherManagerState>(useTeacherStore()),
    });
    container.register<ServerStateManager.TodayAttendanceStudentManager>(
        "TodayAttendanceStudentManager",
        {
            useValue: new StoreManagerImpl<TodayAttendanceStudentState>(
                useTodayAttendanceStudentStore(),
            ),
        },
    );
    container.register<ServerStateManager.ChallengeTemplateManager>("ChallengeTemplateManager", {
        useValue: new StoreManagerImpl<ChallengeTemplateState>(useChallengesTemplateStore()),
    });
    container.register<ServerStateManager.ChallengeManager>("ChallengeManager", {
        useValue: new StoreManagerImpl<ChallengeState>(useChallengesStore()),
    });
    container.register<ServerStateManager.StudentChallengesManager>("StudentChallengesManager", {
        useValue: new StoreManagerImpl<StudentChallengesState>(useStudentChallengesStore()),
    });
    container.register<ServerStateManager.StudentSubmittedProblemsManager>(
        "StudentSubmittedProblemsManager",
        {
            useValue: new StoreManagerImpl<StudentSubmittedProblemsState>(
                useStudentSubmittedProblemsStore(),
            ),
        },
    );
};

export default stateRegisterInit;
