import { defineStore } from "pinia";

import type { ITeacherManagerState, IAcademyTeachersDto, ISimplifiedUserDto } from "./interface";

const useTeacherStore = defineStore("teacherStore", {
    state: (): ITeacherManagerState => ({
        academyTeachersDto: null,
        totalCount: 0,
        teachers: [],
    }),
    actions: {
        setAcademyTeachersDto(responseDto: IAcademyTeachersDto) {
            this.academyTeachersDto = responseDto;
        },
        setTotalCount(totalCount: number) {
            this.totalCount = totalCount;
        },
        setTeachers(teachers: ISimplifiedUserDto[]) {
            this.teachers = teachers;
        },
        resetState() {
            this.academyTeachersDto = null;
            this.totalCount = 0;
            this.teachers = [];
        },
    },
});

export default useTeacherStore;
