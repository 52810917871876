import { defineStore } from "pinia";

import type { ITodayAttendanceStudentState, IAcademyTodayAttendanceUserDto } from "./interface";

const useTodayAttendanceStudentStore = defineStore("todayAttendanceStudent", {
    state: (): ITodayAttendanceStudentState => ({
        academyTodayAttendanceStudentDto: null,
        academyTodayAttendanceStudentId: "",
        targetStudentId: "",
    }),
    actions: {
        setAcademyTodayAttendanceStudentDto(responseDto: IAcademyTodayAttendanceUserDto) {
            this.academyTodayAttendanceStudentDto = responseDto;
        },
        setAcademyTodayAttendanceStudentId(academyRegistrationId: string) {
            this.academyTodayAttendanceStudentId = academyRegistrationId;
        },
        setTargetStudentId(targetStudentId: string) {
            this.targetStudentId = targetStudentId;
        },
        resetState() {
            this.academyTodayAttendanceStudentDto = null;
            this.academyTodayAttendanceStudentId = "";
            this.targetStudentId = "";
        },
    },
});

export default useTodayAttendanceStudentStore;
