import { defineStore } from "pinia";

import type {
    AlertState,
    ComponentPropsInAlert,
} from "@/adapter/common/stores/client/alert/interface";

const useAlertStore = defineStore<"alertStore", AlertState<keyof ComponentPropsInAlert>>({
    id: "alertStore",
    state: () => ({
        alertOpen: false,
        alertComponent: null,
        alertProps: null,
    }),
    actions: {
        onmountAlertModal<T extends keyof ComponentPropsInAlert>(
            component: T,
            props: ComponentPropsInAlert[T],
        ) {
            this.alertComponent = component;
            this.alertProps = props ? { ...props } : null;
            this.alertOpen = true;
        },
        unmountAlertModal() {
            this.alertOpen = false;
            this.alertComponent = null;
            this.alertProps = null;
        },
        unmountAlertWhenReconfirm() {
            this.alertOpen = false;
        },
    },
});

export default useAlertStore;
