import { ResponseDtos } from "@/domain/common/interface/slc-application/dto/reponses/dtos";
import { ApplicationEnums } from "@/domain/common/interface/slc-application/enum/enums";

export interface PrincipalDto extends ResponseDtos.PrincipalTeacherDto {}
export const UserType = ApplicationEnums.UserType;

export interface AuthState {
    userDto: ResponseDtos.UserDto | null;
    userEmail: string;
    academyId: string;
    teacherId: string;
    userType: number;
    userAccessToken: string | null;
    userRefreshToken: string | null;
}
