import { defineStore } from "pinia";

import type {
    IStudentSubmittedProblemsState,
    IStudentSubmittedProblemsActions,
    IGetStudentSubmittedProblemsResponseDto,
} from "./interface";

const useStudentSubmittedProblemsStore = defineStore<
    "studentSubmittedProblemsStore",
    IStudentSubmittedProblemsState,
    {},
    IStudentSubmittedProblemsActions
>("studentSubmittedProblemsStore", {
    state: (): IStudentSubmittedProblemsState => ({
        studentSubmittedProblemsDto: null,
        nextToken: null,
    }),
    actions: {
        setStudentSubmittedProblemsDto(dto: IGetStudentSubmittedProblemsResponseDto) {
            this.studentSubmittedProblemsDto = dto;
        },
        setNextToken(nextToken: string) {
            this.nextToken = nextToken;
        },
        resetState() {
            this.studentSubmittedProblemsDto = null;
            this.nextToken = null;
        },
    },
});

export default useStudentSubmittedProblemsStore;
