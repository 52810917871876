import { container } from "tsyringe";

// 생각해보면 factory 내에서 store 를 생성하는것이 테스트에서 용이할지는 의문이 듭니다.
// store 하나하나 구현체로서 주입되는것이 아니라면 테스트에서 좀 더 어려울 수 있다는 판단입니다.
// 따라서 현재 stateRegister 내에서 모든 server State 를 따로 등록해주고
// 이에 대한 타입을 application 내에서 사용하도록 하겠습니다. (여기서도 정의합니다. 왜냐하면 UI 에서도 사용될 수 있으니)

// impl
import StoreManagerImpl from "@/infrastructure/libs/state-management";

// stores
import useAlertStore from "@/adapter/common/stores/client/alert";
import useCurriculumClientStore from "@/adapter/common/stores/client/curriculum";
import useLoadingStore from "@/adapter/common/stores/client/loading";
import useModalStore from "@/adapter/common/stores/client/modal";
import useModalSecondLayerStore from "@/adapter/common/stores/client/modal-second-layer";
import useAcademyClassStore from "@/adapter/common/stores/client/academy-class";

// types
import type {
    AlertState,
    ComponentPropsInAlert,
} from "@/adapter/common/stores/client/alert/interface";
import type { CurriculumClientState } from "@/adapter/common/stores/client/curriculum/interface";
import type { LoadingState } from "@/adapter/common/stores/client/loading/interface";
import type {
    ModalState,
    ComponentPropsInModal,
    ModalLayout,
} from "@/adapter/common/stores/client/modal/interface";
import type {
    ModalSecondLayerState,
    ComponentPropsInModalSecondLayer,
    ModalLayout as SecondModalLayout,
} from "@/adapter/common/stores/client/modal-second-layer/interface";
import type { AcademyClassState } from "@/adapter/common/stores/client/academy-class/interface";

import { ClientStateManager } from "@/adapter/common/stores/client/interface";

const clientStateRegisterInit = () => {
    container.register<ClientStateManager.AlertManager>("AlertManager", {
        useValue: new StoreManagerImpl<AlertState<keyof ComponentPropsInAlert>>(useAlertStore()),
    });
    container.register<ClientStateManager.CurriculumClientManager>("CurriculumClientManager", {
        useValue: new StoreManagerImpl<CurriculumClientState>(useCurriculumClientStore()),
    });
    container.register<ClientStateManager.LoadingManager>("LoadingManager", {
        useValue: new StoreManagerImpl<LoadingState>(useLoadingStore()),
    });
    container.register<ClientStateManager.ModalManager>("ModalManager", {
        useValue: new StoreManagerImpl<ModalState<keyof ComponentPropsInModal, ModalLayout>>(
            useModalStore(),
        ),
    });
    container.register<ClientStateManager.ModalSecondLayerManager>("ModalSecondLayerManager", {
        useValue: new StoreManagerImpl<
            ModalSecondLayerState<keyof ComponentPropsInModalSecondLayer, SecondModalLayout>
        >(useModalSecondLayerStore()),
    });
    container.register<ClientStateManager.AcademyClassManager>("AcademyClassManager", {
        useValue: new StoreManagerImpl<AcademyClassState>(useAcademyClassStore()),
    });
};

export default clientStateRegisterInit;
