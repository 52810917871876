import { singleton } from "tsyringe";

import type {
    CommonAccountsPath,
    ConnectCommonAccountsOptions,
} from "@/adapter/common/stores/server/interface";

@singleton()
export default class WindowPopupHandler {
    private setAccountsBaseUrl = (
        path: CommonAccountsPath,
        email?: string,
        accessToken?: string,
        action?: string,
    ): string => {
        const newBaseUrl = new URL(path, import.meta.env.VITE_COMMON_ACCOUNTS_URL);

        newBaseUrl.searchParams.set("service", import.meta.env.VITE_SERVICE_ID);

        if (email) {
            newBaseUrl.searchParams.set("email", email);
        }

        if (accessToken) {
            newBaseUrl.searchParams.set("accessToken", accessToken);
        }

        if (action) {
            newBaseUrl.searchParams.set("action", action);
        }

        return newBaseUrl.toString();
    };

    private setPopupParams = () => {
        let params = "";

        params += `width= ` + screen.width;
        params += `, height= ` + screen.height;
        params += `, top=0, left=0`;
        params += `, fullscreen=yes`;

        return params;
    };

    public connect = (path: CommonAccountsPath, options?: ConnectCommonAccountsOptions) => {
        const popupBaseUrl = this.setAccountsBaseUrl(
            path,
            options?.email,
            options?.accessToken,
            options?.action,
        );
        const params = this.setPopupParams();

        const openPopup = window.open(popupBaseUrl, "redService", params);

        return openPopup;
    };
}
