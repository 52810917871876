import { container } from "tsyringe";

// impl
import { CreateOrderKeyImpl } from "@/infrastructure/libs/interface";

// create-order-key
import CreateOrderKeyAdapter from "@/infrastructure/libs/create-order-key/index";
import FractionalIndexing from "@/infrastructure/libs/create-order-key/FractionalIndexing";

const libsRegisterInit = () => {
    container.register<CreateOrderKeyImpl>("CreateOrderKey", {
        useValue: new CreateOrderKeyAdapter(new FractionalIndexing()),
    });
};

export default libsRegisterInit;
