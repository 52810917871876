import { singleton, inject } from "tsyringe";

// domain
import {
    ChallengeRepository,
    ProvideChallengeForm,
    RequestGetChallenges,
    RequestGetChallengesBaseTemplateId,
} from "@/domain/challenge/repositories/repositories";
import { ChallengeDto, GetChallengesResponseDto } from "@/domain/challenge/dtos";

// infrastructure
import HttpAdaptor from "@/infrastructure/libs/http";

@singleton()
export default class ChallengeRepositoryImpl implements ChallengeRepository {
    constructor(@inject(HttpAdaptor) private readonly server: HttpAdaptor) {}

    /** @description 챌린지 목록 조회 */
    getChallenges = async (request: RequestGetChallenges) => {
        let url = `/v1/challenges?academyId=${request.academyId}&include=summary&`;

        // 챌린지 타입 반복 추가
        request.type.forEach((type) => {
            url += `type=${type}&`;
        });

        // 챌린지 완료 status 추가
        request.status?.forEach((status) => {
            url += `status=${status}&`;
        });

        if (request.from) {
            url += `from=${request.from}&`;
        }

        if (request.to) {
            url += `to=${request.to}&`;
        }

        url += `keyword=${request.keyword}&`;
        url += `size=${request.size}&`;
        url += `order=${request.order}&`;

        if (request.nextToken) {
            url += `nextToken=${request.nextToken}`;
        }

        const response = await this.server.get<GetChallengesResponseDto>(url);

        return response;
    };

    /** @description 특정 templateId 에 해당하는 챌린지 리스트 조히 */
    getChallengesBaseTemplateId = async (request: RequestGetChallengesBaseTemplateId) => {
        let url = `/v1/challenges?academyId=${request.academyId}&include=summary&`;

        url += `challengeTemplateId=${request.challengeTemplateId}&`;
        url += `keyword=${request.keyword}&`;
        url += `size=${request.size}&`;
        url += `order=${request.order}&`;

        request.status.forEach((status) => {
            url += `status=${status}&`;
        });

        if (request.nextToken) {
            url += `nextToken=${request.nextToken}`;
        }

        const response = await this.server.get<GetChallengesResponseDto>(url);

        return response;
    };

    /** @description 다중 학생에게 챌린지를 부여하기  */
    provideChallenges = async (request: ProvideChallengeForm[]) => {
        const url = `/v1/challenges`;

        const response = await this.server.post<ChallengeDto[]>(url, request);

        return response;
    };

    /** @description 특정 학생의 챌린지의 부여를 취소합니다. */
    cancelChallenge = async (challengeId: string) => {
        const url = `/v1/challenges/${challengeId}`;

        await this.server.delete(url);
    };
}
