<script setup lang="ts">
import { defineAsyncComponent, computed, ref, onMounted, onUnmounted } from "vue";
import { container } from "tsyringe";

// adapter
import { ClientStateManager } from "@/adapter/common/stores/client/interface";

// ui
import RootErrorBoundary from "@/ui/shared/error/RootErrorBoundary.vue";

import useCheckMouseInitialClick from "@/ui/shared/components/modals/useCheckMouseInitialClick";

// instance
const modalManager = container.resolve<ClientStateManager.ModalManager>("ModalManager");
const modalState = modalManager.getState();

// modal 의 body 부분을 ref 로 참조합니다.
const dimRef = ref<HTMLDivElement | null>(null);
// 마우스의 누름과 띄움에 따른 이벤트를 실행하고자 호출합니다.
const { mount, unmount } = useCheckMouseInitialClick(dimRef);

const component = computed(() => {
    const modalLayoutPath = modalState.modalLayout;
    return defineAsyncComponent(() => import(`./container/${modalLayoutPath}.vue`));
});

onMounted(() => {
    // 해당 modal 이 렌더링 되었다면 마우스 이벤트를 등록합니다. (addEventListner)
    if (dimRef.value) {
        mount();
    }
});

onUnmounted(() => {
    // 해당 modal 이 사라졌다면 마우스 이벤트를 제거합니다. (removeEventListner)
    unmount();
});
</script>

<template>
    <div class="flex items-center justify-center h-auto w-fit z-999" tabindex="-1" ref="dimRef">
        <RootErrorBoundary>
            <Transition name="fade">
                <component :is="component" />
            </Transition>
        </RootErrorBoundary>
    </div>
</template>

<style scoped lang="scss">
.fade-enter-active,
.fade-leave-active {
    transition: all 0.25s ease;
}

.fade-enter-from,
.fade-leave-to {
    transform: translateY(-20px);

    opacity: 0;
}
</style>
