import { defineStore } from "pinia";

import type {
    IPendingRegistrationStudentState,
    IAcademyRegistrationUserDto,
    IUserType,
} from "./interface";

const usePendingRegistrationStudentStore = defineStore("pendingRegistrationStudent", {
    state: (): IPendingRegistrationStudentState => ({
        academyRegistrationDto: null,
        academyRegistrationId: "",
        userType: null,
        targetUserId: "",
    }),
    actions: {
        setAcademyRegistrationDto(responseDto: IAcademyRegistrationUserDto) {
            this.academyRegistrationDto = responseDto;
        },
        setAcademyRegistrationId(academyRegistrationId: string) {
            this.academyRegistrationId = academyRegistrationId;
        },
        setUserType(userType: IUserType) {
            this.userType = userType;
        },
        setTargetUserId(targetUserId: string) {
            this.targetUserId = targetUserId;
        },
        resetState() {
            this.academyRegistrationDto = null;
            this.academyRegistrationId = "";
            this.userType = null;
            this.targetUserId = "";
        },
    },
});

export default usePendingRegistrationStudentStore;
