import { singleton, inject } from "tsyringe";
import FractionalIndexing from "@/infrastructure/libs/create-order-key/FractionalIndexing";

import type { CreateOrderKeyImpl } from "@/infrastructure/libs/interface";

@singleton()
export default class CreateOrderKeyAdapter implements CreateOrderKeyImpl {
    constructor(
        @inject(FractionalIndexing) private readonly fractionalIndexing: FractionalIndexing,
    ) {}

    public generateKeyBetween = (start: string | null, end: string | null) => {
        return this.fractionalIndexing.generateKeyBetween(start, end);
    };

    public generateNKeysBetween = (start: string | null, end: string | null, n: number) => {
        return this.fractionalIndexing.generateNKeysBetween(start, end, n);
    };
}
