import { defineStore } from "pinia";

import type { IStudentManagerState, IStudentDto } from "./interface";

const useStudentStore = defineStore("studentStore", {
    state: (): IStudentManagerState => ({
        academyStudentDto: null,
        academyStudentsDto: null,
    }),
    getters: {
        getStudentId: (state) => {
            return state.academyStudentDto?.id;
        },
        getCurriculumMetadata: (state) => {
            return state.academyStudentDto?.curriculumMetadata;
        },
    },
    actions: {
        setAcademyStudentDto(responseDto: IStudentDto) {
            this.academyStudentDto = responseDto;
        },
        setAcademyStudentsDto(responseDto: IStudentDto[]) {
            this.academyStudentsDto = responseDto;
        },
        resetState() {
            this.academyStudentDto = null;
            this.academyStudentsDto = null;
        },
    },
});

export default useStudentStore;
