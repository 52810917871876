import { defineStore } from "pinia";
import type {
    IAttendanceRecordDto,
    IAttendanceRecordManagerState,
    IStudentAttendanceStatus,
    IAttendanceType,
} from "./interface";

const useAttendanceRecordStore = defineStore("attendanceRecordStore", {
    state: (): IAttendanceRecordManagerState => ({
        attendanceTotalCounts: {
            Total: null,
            OnTime: null,
            Late: null,
            Absence: null,
            Additional: null,
        },
        attendanceRecordRange: {
            from: null,
            to: null,
        },
        status: "Total",
        adjustRangeMode: "init",
        attendanceNextTokenArray: {
            Total: [null],
            OnTime: [null],
            Late: [null],
            Absence: [null],
            Additional: [null],
        },
        categorizedResult: {
            Total: null,
            OnTime: null,
            Late: null,
            Absence: null,
            Additional: null,
        },
        rangeBaseAttendanceTotalCounts: {
            Total: null,
            OnTime: null,
            Late: null,
            Absence: null,
            Additional: null,
        },
        rangeBaseAttendanceNextTokenArray: {
            Total: [null],
            OnTime: [null],
            Late: [null],
            Absence: [null],
            Additional: [null],
        },
        rangeBaseCategorizedResult: {
            Total: null,
            OnTime: null,
            Late: null,
            Absence: null,
            Additional: null,
        },
    }),
    getters: {
        /** @description 상태에 따른 검색결과를 가져옵니다. */
        getResultByStatus: (state) => {
            if (state.attendanceRecordRange.from && state.attendanceRecordRange.to) {
                return state.rangeBaseCategorizedResult[state.status];
            }

            return state.categorizedResult[state.status];
        },

        /** @description index 에 따라 nextToken을 가져옵니다. */
        getNextTokenBaseStatusByIndex: (state) => (index: number) => {
            // 이미 range 를 선택해둔 상황이라면
            if (state.attendanceRecordRange.from && state.attendanceRecordRange.to) {
                return state.rangeBaseAttendanceNextTokenArray[state.status][index];
            }

            return state.attendanceNextTokenArray[state.status][index];
        },

        /** @description 페이지내이션을 위해 token array 를 return 합니다. */
        getTokenArray: (state) => {
            if (state.attendanceRecordRange.from && state.attendanceRecordRange.to) {
                return state.rangeBaseAttendanceNextTokenArray[state.status];
            }

            return state.attendanceNextTokenArray[state.status];
        },

        /** @description range 에 따른 검색 결과를 가져옵니다. */
        getRangeBaseResultByStatus: (state) => {
            return state.rangeBaseCategorizedResult[state.status];
        },
    },
    actions: {
        /**
         * @description
         * 검색어를 저장합니다.
         * 추후 cache 기능을 적용할 시 활용할 수 있습니다.
         *  */
        setAttendanceRange(range: Date, type: "from" | "to") {
            this.attendanceRecordRange[type] = range;
        },

        /** @description 상태릂 변경합니다. */
        changeStatus(status: IStudentAttendanceStatus) {
            this.status = status;
        },

        // common
        changeTypeToStatus(type: IAttendanceType[]) {
            const createKey = type.join(",");

            const typeMap = {
                "0,1,2,3": "Total",
                "0": "OnTime",
                "1": "Late",
                "2": "Additional",
                "3": "Absence",
            };

            return typeMap[createKey];
        },

        changeRangeMode(mode: "init" | "total" | "range") {
            this.adjustRangeMode = mode;
        },

        // total

        /**
         * @description
         * 검색 결과를 상태별로 저장합니다.
         * 이 부분은 api 의 응답에 따라 완전히 변경될 예정입니다. */
        setPartialAttendanceRecord(type: IAttendanceType[], partialData: IAttendanceRecordDto) {
            if (this.attendanceRecordRange.from && this.attendanceRecordRange.to) {
                this.setPartialRangeBaseAttendanceRecord(type, partialData);
                return;
            }

            this.categorizedResult[this.changeTypeToStatus(type)] = partialData;
        },

        /** @description 전체 count 을 저장합니다. */
        setTotalCount(count: number, type: IAttendanceType[]) {
            const status = this.changeTypeToStatus(type);
            if (this.attendanceRecordRange.from && this.attendanceRecordRange.to) {
                this.rangeBaseAttendanceTotalCounts[status] = count;
                return;
            }

            this.attendanceTotalCounts[status] = count;
        },

        /** @description nextToken 을 저장합니다. */
        setNextToken(token: string, type: IAttendanceType[]) {
            const status = this.changeTypeToStatus(type);
            if (this.attendanceRecordRange.from && this.attendanceRecordRange.to) {
                this.setRangeBaseNextToken(token, type);
                return;
            }

            if (this.attendanceNextTokenArray[status].includes(token)) return;

            this.attendanceNextTokenArray[status].push(token);
        },

        // rangeBase

        /** @description range 에 따른 table 데이터를 저장합니다. */
        setPartialRangeBaseAttendanceRecord(
            type: IAttendanceType[],
            partialData: IAttendanceRecordDto,
        ) {
            this.rangeBaseCategorizedResult[this.changeTypeToStatus(type)] = partialData;
        },

        /** @description range 에 따른 count 를 저장합니다. */
        setRangeBaseTotalCount(count: number, type: IStudentAttendanceStatus) {
            this.rangeBaseAttendanceTotalCounts[type] = count;
        },

        /** @description range 에 따른 NextToken 을 저장합니다. */
        setRangeBaseNextToken(token: string, type: IAttendanceType[]) {
            const status = this.changeTypeToStatus(type);
            if (this.rangeBaseAttendanceNextTokenArray[status].includes(token)) return;

            this.rangeBaseAttendanceNextTokenArray[status].push(token);
        },

        /** @description range 기반 데이터를 초기화합니다. */
        resetRangeBaseState() {
            (this.status = "Total"),
                (this.attendanceRecordRange = {
                    from: null,
                    to: null,
                }),
                (this.rangeBaseAttendanceTotalCounts = {
                    Total: null,
                    OnTime: null,
                    Late: null,
                    Absence: null,
                    Additional: null,
                }),
                (this.rangeBaseAttendanceNextTokenArray = {
                    Total: [null],
                    OnTime: [null],
                    Late: [null],
                    Absence: [null],
                    Additional: [null],
                }),
                (this.rangeBaseCategorizedResult = {
                    Total: null,
                    OnTime: null,
                    Late: null,
                    Absence: null,
                    Additional: null,
                });
        },

        /** @description 모든 데이터를 초기화합니다. */
        resetState() {
            (this.attendanceTotalCounts = {
                Total: null,
                OnTime: null,
                Late: null,
                Absence: null,
                Additional: null,
            }),
                (this.attendanceRecordRange = {
                    from: null,
                    to: null,
                }),
                (this.status = "Total"),
                (this.adjustRangeMode = "init"),
                (this.attendanceNextTokenArray = {
                    Total: [null],
                    OnTime: [null],
                    Late: [null],
                    Absence: [null],
                    Additional: [null],
                }),
                (this.categorizedResult = {
                    Total: null,
                    OnTime: null,
                    Late: null,
                    Absence: null,
                    Additional: null,
                }),
                (this.rangeBaseAttendanceTotalCounts = {
                    Total: null,
                    OnTime: null,
                    Late: null,
                    Absence: null,
                    Additional: null,
                }),
                (this.rangeBaseAttendanceNextTokenArray = {
                    Total: [null],
                    OnTime: [null],
                    Late: [null],
                    Absence: [null],
                    Additional: [null],
                }),
                (this.rangeBaseCategorizedResult = {
                    Total: null,
                    OnTime: null,
                    Late: null,
                    Absence: null,
                    Additional: null,
                });
        },
    },
});

export default useAttendanceRecordStore;
