import { defineStore } from "pinia";

import type {
    IPendingRegistrationsRecordState,
    IGetAcademyRegistrationList,
    IAcademyRegistrationResponseDto,
} from "./interface";

const usePendingRegistrationRecordStore = defineStore("pendingRegistrationRecord", {
    state: (): IPendingRegistrationsRecordState => ({
        pendingRegistrationsRecordDtos: null,
        academyRegistrationList: [],
        nextToken: null,
    }),
    actions: {
        setPendingRegistrationRecordDto(responseDto: IGetAcademyRegistrationList) {
            this.pendingRegistrationsRecordDtos = responseDto;
        },
        setAcademyRegistrationList(academyRegistrationDtos: IAcademyRegistrationResponseDto[]) {
            this.academyRegistrationList = academyRegistrationDtos;
        },
        resetState() {
            this.pendingRegistrationsRecordDtos = null;
            this.academyRegistrationList = [];
            this.nextToken = null;
        },
    },
});

export default usePendingRegistrationRecordStore;
