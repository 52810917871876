import { singleton } from "tsyringe";
import { Store, StoreState } from "pinia";

// StateManagerInit
export interface StateManager<T> {
    getState(): StoreState<T>;
    setState(state: Partial<T>): void;
    subscribe(callback: (state: T) => void): () => void;
    dispatch(action: string, payload?: any): void;
}

@singleton()
export default class PiniaStoreAdapter<T> implements StateManager<T> {
    private store: Store;
    constructor(store: Store) {
        this.store = store;
    }

    getState = () => {
        return this.store.$state as StoreState<T>;
    };

    getGetter = (getter: string) => {
        return this.store[getter];
    };

    setState = (state: Partial<T>) => {
        return this.store.$patch(state);
    };

    subscribe = (callback: (state: T) => void) => {
        const unsubscribe = this.store.$subscribe((_mutation, state) => {
            callback(state as T);
        });
        return unsubscribe;
    };

    dispatch = (action: string, args?: any[]) => {
        if (typeof this.store[action] === "function") {
            if (args !== undefined) {
                this.store[action](...args);
            } else {
                this.store[action]();
            }
        } else {
            new Error(`Action ${action} is not defined`);
        }
    };
}
