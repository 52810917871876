import { singleton, inject } from "tsyringe";

// domain
import type { RequestGetStudentChallenges } from "@/domain/academy/student-challenge/repositories/repositories";
import type { GetStudentChallengesResponseDto } from "@/domain/academy/student-challenge/dtos";
import { StudentChallengeRepository } from "@/domain/academy/student-challenge/repositories/repositories";

// infrastructure
import HttpAdaptor from "@/infrastructure/libs/http";

@singleton()
export default class StudentChallengesRepositoryImpl implements StudentChallengeRepository {
    constructor(@inject(HttpAdaptor) private readonly server: HttpAdaptor) {}

    getStudentChallenges = async (request: RequestGetStudentChallenges) => {
        let url = `/v1/academies/${request.academyId}/student-challenges?`;

        url += `challengeTemplateId=${request.challengeTemplateId}&`;

        if ("fromAssignedCount" in request) {
            url += `fromAssignedCount=${request.fromAssignedCount}&`;
        }

        if ("toAssignedCount" in request) {
            url += `toAssignedCount=${request.toAssignedCount}&`;
        }

        if (request.keyword) {
            url += `keyword=${request.keyword}&`;
        }

        if (request.size) {
            url += `size=${request.size}&`;
        }

        if (request.nextToken) {
            url += `nextToken=${request.nextToken}`;
        }

        const response = await this.server.get<GetStudentChallengesResponseDto>(url);

        return response;
    };
}
