import { singleton } from "tsyringe";
import { generateKeyBetween, generateNKeysBetween } from "fractional-indexing";

@singleton()
export default class FractionalIndexing {
    generateKeyBetween = (start: string | null, end: string | null) => {
        return generateKeyBetween(start, end);
    };

    generateNKeysBetween = (start: string | null, end: string | null, n: number) => {
        return generateNKeysBetween(start, end, n);
    };
}
