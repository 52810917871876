import { ApplicationEnums } from "@/domain/common/interface/slc-application/enum/enums";

export enum StudyItemTypeNames {
    Preview = "프리뷰",
    ReviewTest = "복습 테스트",
    RandomTest = "렌텀 테스트",
    MockExam = "모의고사",
    Review = "복습하기",

    Concept = "개념 정리하기",
    ConceptPractice = "개념 연습하기",
    ConceptReinforcement = "개념 다지기",
    ConceptTest = "개념 테스트",
    ConceptApplication = "개념 활용하기",
    ConceptReview = "개념 점검하기",

    ConceptBasedChecking = "개념별 훈련하기",
    TypeBasedChecking = "유형별 훈련하기",
    ProblemInterpretation = "문제 해석하기",

    ConceptWrapUp = "개념 마무리",
    MiddleUnitWrapUpStep1 = "중단원 마무리 1step",
    MiddleUnitWrapUpStep2 = "중단원 마무리 2step",

    AdvancedStep1 = "심화 1step",
    AdvancedStep2 = "심화 2step",
    AdvancedStep3 = "심화 3step",

    MiddleUnitTest1 = "중단원 테스트 1회",
    MiddleUnitTest2 = "중단원 테스트 2회",
    BigUnitTest1 = "대단원 테스트 1회",
    BigUnitTest2 = "대단원 테스트 2회",
    Additional = "보충 학습",
    TestWarmingUp = "테스트 워밍업",
    Special = "특강 수업",
}

export enum StudyItemTypeDescriptions {
    Concept = "개념 학습 후 빈칸을 채우며 확인하는 단계입니다.",
    ConceptPractice = "개념과 관련된 연산 연습 단계입니다.",
    ConceptReinforcement = "기본 문제를 통해 개념을 이해하는 단계입니다.",
    ConceptTest = "개념을 제대로 이해했는지 확인하는 단계입니다.",
    ConceptApplication = "개념을 문제 풀이에 직접 적용해 보는 단계입니다.",
    ConceptReview = "개념을 활용하여 문제를 풀고 있는지 점검하는 단계입니다.",

    ConceptBasedChecking = "개념별 대표 문제를 푼 후, 정오답에 따라 추가 학습 문제를 풀어 보는 단계입니다.",
    TypeBasedChecking = "유형별 대표 문제를 푼 후, 정오답에 따라 추가 학습 문제를 풀어 보는 단계입니다.",
    ProblemInterpretation = "문제를 해석하여 효율적으로 푸는 방법을 연습하는 단계입니다.",

    ConceptWrapUp = "중단원별 개념 학습을 마무리하는 문제를 푸는 단계입니다.",
    MiddleUnitWrapUpStep1 = "적용 학습을 마무리하는 단계입니다.",
    MiddleUnitWrapUpStep2 = "더 높은 난이도의 문제들을 풀어보며 적용 학습을 마무리하는 단계입니다.",

    AdvancedStep1 = "대단원의 핵심 문제들을 풀어보는 심화 학습 단계입니다.",
    AdvancedStep2 = "대단원의 확장 개념과 특별한 풀이법을 배우는 심화 학습 단계입니다.",
    AdvancedStep3 = "배운 모든 개념과 풀이법을 이용하여 최고 난이도의 문제를 풀어 보는 심화 학습 단계입니다.",

    MiddleUnitTest1 = "중단원을 잘 학습하였는지 점검하는 단계입니다.",
    MiddleUnitTest2 = "중단원을 잘 학습하였는지 점검하는 단계입니다.",
    BigUnitTest1 = "대단원의 핵심 문제들을 풀어보는 심화 학습 단계입니다.",
    BigUnitTest2 = "대단원의 핵심 문제들을 풀어보는 심화 학습 단계입니다.",
    Special = "특정 학생을 대상으로 한 학습 단계입니다.",
}
