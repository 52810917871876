import { singleton } from "tsyringe";

@singleton()
export default class TakeFirstPromiseProcessor {
    promise: Promise<any> | null;
    constructor() {
        this.promise = null;
    }
    public process = <Args extends unknown[], T>(requestFn: (...args: Args) => Promise<T>) => {
        return async (...args: Args): Promise<T> => {
            if (this.promise) return this.promise;

            this.promise = requestFn(...args).finally(() => {
                this.promise = null;
            });

            return this.promise;
        };
    };
}
