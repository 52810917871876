import { injectable } from "tsyringe";
import { Store } from "pinia";
import PiniaStoreAdapter from "./pinia";

@injectable()
export default class StoreManagerImpl<T> {
    private storeAdapter: PiniaStoreAdapter<T>;
    constructor(store: Store) {
        this.storeAdapter = new PiniaStoreAdapter(store);
    }

    getState = () => {
        return this.storeAdapter.getState();
    };

    getGetter = (getter: string) => {
        return this.storeAdapter.getGetter(getter);
    };

    setState = (state: Partial<T>) => {
        return this.storeAdapter.setState(state);
    };

    subscribe = (callback: (state: T) => void) => {
        return this.storeAdapter.subscribe(callback);
    };

    dispatch = (action: string, args?: any[]) => {
        return this.storeAdapter.dispatch(action, args);
    };
}
