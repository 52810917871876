import { StudyItemTypeNames, StudyItemTypeDescriptions } from "@/domain/curriculum/enums";

export const studyItemTypesSet = [
    {
        id: 1,
        studyType: {
            id: 1,
            name: "개념 학습",
        },
        studyTypeUnits: [
            {
                id: 8,
                name: StudyItemTypeNames.Concept,
                description: StudyItemTypeDescriptions.Concept,
            },
            {
                id: 9,
                name: StudyItemTypeNames.ConceptPractice,
                description: StudyItemTypeDescriptions.ConceptPractice,
            },
            {
                id: 10,
                name: StudyItemTypeNames.ConceptReinforcement,
                description: StudyItemTypeDescriptions.ConceptReinforcement,
            },
            {
                id: 11,
                name: StudyItemTypeNames.ConceptTest,
                description: StudyItemTypeDescriptions.ConceptTest,
            },
            {
                id: 12,
                name: StudyItemTypeNames.ConceptApplication,
                description: StudyItemTypeDescriptions.ConceptApplication,
            },
            {
                id: 13,
                name: StudyItemTypeNames.ConceptReview,
                description: StudyItemTypeDescriptions.ConceptReview,
            },
            {
                id: 17,
                name: StudyItemTypeNames.ConceptWrapUp,
                description: StudyItemTypeDescriptions.ConceptWrapUp,
            },
        ],
    },
    {
        id: 2,
        studyType: {
            id: 2,
            name: "적용 학습",
        },
        studyTypeUnits: [
            {
                id: 14,
                name: StudyItemTypeNames.ConceptBasedChecking,
                description: StudyItemTypeDescriptions.ConceptBasedChecking,
            },
            {
                id: 15,
                name: StudyItemTypeNames.TypeBasedChecking,
                description: StudyItemTypeDescriptions.TypeBasedChecking,
            },
            {
                id: 16,
                name: StudyItemTypeNames.ProblemInterpretation,
                description: StudyItemTypeDescriptions.ProblemInterpretation,
            },
            {
                id: 18,
                name: StudyItemTypeNames.MiddleUnitWrapUpStep1,
                description: StudyItemTypeDescriptions.MiddleUnitWrapUpStep1,
            },
            {
                id: 19,
                name: StudyItemTypeNames.MiddleUnitWrapUpStep2,
                description: StudyItemTypeDescriptions.MiddleUnitWrapUpStep2,
            },
        ],
    },
    {
        id: 3,
        studyType: {
            id: 3,
            name: "테스트 학습",
        },
        studyTypeUnits: [
            {
                id: 23,
                name: StudyItemTypeNames.MiddleUnitTest1,
                description: StudyItemTypeDescriptions.MiddleUnitTest1,
            },
            {
                id: 24,
                name: StudyItemTypeNames.BigUnitTest1,
                description: StudyItemTypeDescriptions.BigUnitTest1,
            },
        ],
    },
    {
        id: 4,
        studyType: {
            id: 4,
            name: "히든 학습",
        },
        studyTypeUnits: [
            {
                id: 20,
                name: StudyItemTypeNames.AdvancedStep1,
                description: StudyItemTypeDescriptions.AdvancedStep1,
            },
            {
                id: 21,
                name: StudyItemTypeNames.AdvancedStep2,
                description: StudyItemTypeDescriptions.AdvancedStep2,
            },
            {
                id: 22,
                name: StudyItemTypeNames.AdvancedStep3,
                description: StudyItemTypeDescriptions.AdvancedStep3,
            },
        ],
    },
];

export const referenceStudyItemTypes = studyItemTypesSet.flatMap((studyItemType) => {
    return studyItemType.studyTypeUnits.map((studyTypeUnit) => studyTypeUnit.id);
});
