import { injectable, inject } from "tsyringe";

// adapter
import ListenMessageHandler from "@/adapter/common-accounts/workers/ListenMessageHandler";
import WindowPopupHandler from "@/adapter/common-accounts/workers/WindowPopuphandler";

import type {
    CommonAccountsPath,
    ConnectCommonAccountsOptions,
} from "@/adapter/common/stores/server/interface";

@injectable()
export default class ConnectCommonAccountsHandler {
    constructor(
        @inject(ListenMessageHandler) private listenMessageHandler: ListenMessageHandler,
        @inject(WindowPopupHandler) private windowPopupHandler: WindowPopupHandler,
    ) {}

    public connect = (path: CommonAccountsPath, options?: ConnectCommonAccountsOptions) => {
        const openPopup = this.windowPopupHandler.connect(path, options);

        if (openPopup) {
            this.listenMessageHandler.listen(openPopup);
        }
    };
}
