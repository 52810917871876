import { defineStore } from "pinia";

import type { ITodayAttendanceRecordState, IUserDto, IGetExpectedAttendeesList } from "./interface";

const useTodayAttendanceRecordStore = defineStore("todayAttendanceRecord", {
    state: (): ITodayAttendanceRecordState => ({
        todayAttendanceRecordDtos: [],
        expectedAttendeesList: [],
        nextToken: null,
    }),
    actions: {
        setTodayAttendanceRecordDto(responseDto: IGetExpectedAttendeesList) {
            this.todayAttendanceRecordDtos?.push(responseDto);
        },
        setExpectedAttendeesList(userDtos: IUserDto[]) {
            this.expectedAttendeesList?.push(...userDtos);
        },
        resetState() {
            this.todayAttendanceRecordDtos = [];
            this.expectedAttendeesList = [];
            this.nextToken = null;
        },
    },
});

export default useTodayAttendanceRecordStore;
