import { inject, singleton } from "tsyringe";
import AxiosAdaptor from "@/infrastructure/libs/http/axiosFetcher";

interface HttpClient {
    get<T>(url: string, signal?: AbortSignal): Promise<T>;
    post<T>(url: string, data?: any): Promise<T>;
    patch<T>(url: string, data: any): Promise<T>;
    put<T>(url: string, data: any): Promise<T>;
    delete<T>(url: string): Promise<T>;
}

/** 구조체(axios) 변경 대비 layer 설정 */
@singleton()
export default class HttpAdaptor implements HttpClient {
    constructor(@inject(AxiosAdaptor) private readonly axiosAdaptor: AxiosAdaptor) {}

    get<T>(url: string, signal?: AbortSignal): Promise<T> {
        return this.axiosAdaptor.get<T>(url, signal);
    }

    post<T>(url: string, data?: any): Promise<T> {
        return this.axiosAdaptor.post<T>(url, data);
    }

    patch<T>(url: string, data: any): Promise<T> {
        return this.axiosAdaptor.patch<T>(url, data);
    }

    put<T>(url: string, data: any): Promise<T> {
        return this.axiosAdaptor.put<T>(url, data);
    }

    delete<T>(url: string): Promise<T> {
        return this.axiosAdaptor.delete<T>(url);
    }
}
