export namespace ApplicationEnums {
    export enum AttendingPlace {
        Academy = 0,
        Home = 1,
    }

    export enum UserType {
        Student = 0,
        Teacher = 1,
        PrincipalTeacher = 2,
        FranchiseMaster = 10,
        Master = 100,
    }

    export enum AcademyEventType {
        StudyItemCompleted = 0,
        ComboRenewed = 1,
        Asked = 2,
        PointBuffUsed = 3,
    }

    export enum SchoolType {
        Elementary = 0,
        Middle = 1,
        High = 2,
    }

    export enum InvitationRecipientType {
        Student = 0,
        Parent = 1,
    }

    export enum StudyItemType {
        /** 프리뷰 */
        Preview = 1,
        /** 기본학습 (복습테스트) */
        ReviewTest = 2,
        /** 기본학습 (랜덤테스트) */
        RandomTest = 3,
        /** 모의고사 */
        MockExam = 6,
        /** 복습하기 */
        Review = 7,

        /** 개념정리하기 */
        Concept = 8,
        /** 개념연습하기 */
        ConceptPractice = 9,
        /** 개념 다지기 */
        ConceptReinforcement = 10,
        /** 개념 테스트 */
        ConceptTest = 11,
        /** 개념 활용하기 */
        ConceptApplication = 12,
        /** 개념 점검하기 */
        ConceptReview = 13,

        /** 개념별 훈련하기 */
        ConceptBasedChecking = 14,
        /** 유형별 훈련하기 */
        TypeBasedChecking = 15,
        /** 문제해석하기 */
        ProblemInterpretation = 16,

        /** 개념 마무리 */
        ConceptWrapUp = 17,
        /** 중단원 마무리 1step */
        MiddleUnitWrapUpStep1 = 18,
        /** 중단원 마무리 2step */
        MiddleUnitWrapUpStep2 = 19,

        /** 심화 1step */
        AdvancedStep1 = 20,
        /** 심화 2step */
        AdvancedStep2 = 21,
        /** 심화 3step */
        AdvancedStep3 = 22,

        /**  중단원테스트 1회*/
        MiddleUnitTest1 = 23,
        /**  중단원테스트 2회*/
        MiddleUnitTest2 = 25,
        /**  대단원테스트 1회*/
        BigUnitTest1 = 24,
        /**  대단원테스트 2회*/
        BigUnitTest2 = 26,

        /** 보충학습 */
        Additional = 27,
        /** 테스트 워밍업*/
        TestWarmingUp = 28,

        /** 특강 수업 */
        Special = 29,
    }

    export enum CurriculumMetadataMode {
        Beginner = 0,
        Intermediate = 1,
        Advanced = 2,
        UserSettings = 3,
    }

    export enum CurriculumMetadataPolicy {
        TimeBased = 0,
        CurriculumBased = 1,
    }

    export enum Grade {
        ElemantarySchool1 = 1, // 초등학교
        ElemantarySchool2 = 2,
        ElemantarySchool3 = 3,
        ElemantarySchool4 = 4,
        ElemantarySchool5 = 5,
        ElemantarySchool6 = 6,
        MiddleSchool1 = 7, // 중학교
        MiddleSchool2 = 8,
        MiddleSchool3 = 9,
        HighSchool1 = 10, // 고등학교
        HighSchool2 = 11,
        HighSchool3 = 12,
    }

    export enum LevelTestType {
        Concept = 1, // 개념
        Apply = 2, // 적용
    }

    export enum ProblemType {
        MultipleChoice = 1, // 객관식 단답
        MultipleChoiceMultipleAnswer = 2, // 객관식 다답
        ShortAnswer = 3, // 주관식 단답
        ShortAnswerMultipleAnswer = 4, // 주관식 다답
        Objectified = 5, // 객관화 단답
        ObjectifiedMultipleAnswer = 6, // 객관화 다답
        ConsonantSelection = 7, // 자음 선택
        OX = 8, // OX
        SmallQuestion = 9, // 소문항
        Other = 10, // 기타
        ConceptConfirmationProblem = 11, // 개념 확인 문제
        SubProblem = 13, // subproblem 문제
        LevelTypeSubProblem = 14, // subproblem, 단답
        LevelTypeSubProblemMultiple = 15, // subproblem, 다답
    }

    export enum Evaluation {
        Correct = 0,
        Wrong = 1,
    }

    export enum LevelTestGroupStatus {
        NotStarted = 0, // 시작전
        InProgress = 1, // 진행중
        Completed = 2, // 완료
        Canceled = 9, // 취소
    }

    export enum LevelTestGroupType {
        EntranceTest = 1, // 입학고사
        DiagnosticTest = 2, // 진단고사
    }

    export enum TeacherRole {
        MainTeacher = 0,
        SubTeacher = 1,
        TeachingAssistant = 3,
    }

    export enum AttendanceType {
        OnTime = 0,
        Late = 1,
        Additional = 2, // 추가출석 (보강)
        Absence = 3,
    }
    export enum AttendancePlace {
        Academy = 0,
        Home = 1,
    }

    export enum SubmittedAnswerEvaluation {
        Better = 0,
        Normal = 1,
        Worse = 2,
    }

    export const enum SubmittedActionSourceType {
        Study = 0,
        StudyReview = 1,
        Retry = 2,
    }

    export enum PointTransactionType {
        /**
         * @deprecated
         */
        BrowseUserStudy = 1, // 멘토 학습 현황

        StudyProblemHintVideo = 2, // 학습 중 영상 시청
        StudyProblemHintWebtoon = 3, // 학습 중 웹툰 보기
        StudyProblemHintImage = 4, // 학습 중 힌트 이미지
        StudyProblemHintCommentary = 5, // 학습 중 해설 보기
        StudyProblemHintConceptBlock = 6, // 학습 중 개념돋보기

        StudyReviewProblemHintVideo = 7, // 복습 중 영상 시청
        StudyReviewProblemHintWebtoon = 8, // 복습 중 웹툰 보기
        StudyReviewProblemHintImage = 9, // 복습 중 힌트 이미지
        StudyReviewProblemHintCommentary = 10, // 복습 중 해설 보기
        StudyReviewProblemHintConceptBlock = 11, // 복습 중 개념돋보기

        // deprecated
        // ConceptProblemHintVideo = 7, // 학습 중 영상 시청
        // ConceptProblemHintWebtoon = 8, // 학습 중 웹툰 보기
        // ConceptProblemHintImage = 9, // 학습 중 힌트 이미지
        // ConceptProblemHintCommentary = 10, // 학습 중 해설 보기
        // ConceptProblemHintConceptBlock = 11, // 학습 중 개념돋보기
        // ConceptValidationProblemHintVideo = 12, // 학습 중 영상 시청
        // ConceptValidationProblemHintWebtoon = 13, // 학습 중 웹툰 보기
        // ConceptValidationProblemHintImage = 14, // 학습 중 힌트 이미지
        // ConceptValidationProblemHintCommentary = 15, // 학습 중 해설 보기
        // ConceptValidationProblemHintConceptBlock = 16, // 학습 중 개념돋보기
        StudyProblemSkipAsEasy = 17, // 문제 '쉬움'으로 스킵

        AcquireStageReportAccessPermission = 18, // 스테이지 리포트 조회
        AcquireStudyAccessPermission = 19, // 학습 조회

        /**
         * @deprecated
         */
        StudyComplete = 100, // 학습 완료

        StudyProblemCorrect = 101, // 문제 정답
        StudyReviewProblemCorrect = 102, // 리뷰 문제 정답
        ReviewTestMissionCompleted = 103, // 복습 테스트 미션 완료
        RandomTestMissionCompleted = 104, // 랜덤 테스트 미션 완료
        ComboMissionCompleted = 105, // 콤보 미션 완료
        CallMissionCompleted = 106, // 호출 미션 완료
        BetterSolutionMissionCompleted = 107, // 더 나은 풀이 미션 완료
        ReviewMissionCompleted = 108, // 복습하기 미션 완료
        BasicStudyMissionCompleted = 109, // 기본학습 미션 완료
        CompletedDailyMissionCompleted = 110, // 기본학습 미션 완료

        ConceptBlockSelfEvaluationNormal = 201,
        ConceptBlockSelfEvaluationBetter = 202,

        ContentUserMetadataVideo = 300,
        ContentUserMetadataWebtoon = 301,

        ConceptAsset = 400,
        ConceptPracticeAsset = 401,
    }

    export enum SubmittedActionType {
        Answer = 0,
        Skip = 1,
    }

    export enum ContentType {
        Webtoon = 0, // 웹툰
        Video = 1, // 영상
        Tutorial = 2, // 튜토리얼
    }

    export enum ContentEvaluationType {
        Good = 0, // 좋아요
        Bad = 1, // 싫어요
    }

    export enum ConceptBlockSelfEvaluation {
        Better = 0,
        Normal = 1,
        Worse = 2,
    }

    export enum StudyMissionSuccessType {
        Success = 0,
        Fail = 1,
    }

    export enum StudentOrderType {
        EarnedPoint = 0, // 획득 포인트
        CorrectAnswerRate = 1, // 학습 정답률
        // 인기도
    }

    export enum AcademyRegistrationRequestStatus {
        /** 요청 대기 */
        Pending = 0,
        /** 요청 승인*/
        Approved = 1,
        /** 요청 거부 */
        Rejected = 2,
        /** 요청 취소 */
        Canceled = 3,
    }

    export enum StudyItemTypeGroup {
        /** 개념 학습 */
        ConceptLearning = 9991,
        /** 적용 학습 */
        AppliedLearning = 9992,
        /** 테스트 학습 */
        TestLearning = 9993,
        /** 심화 학습 */
        AdvancedLearning = 9994,
    }
}
