<script setup lang="ts">
import { container } from "tsyringe";

// adapter
import { ClientStateManager } from "@/adapter/common/stores/client/interface";

// ui
import CommonModalContent from "@/ui/shared/components/modals/CommonModalContent.vue";

import useCheckMouseInitialClick from "@/ui/shared/components/modals/useCheckMouseInitialClick";

// instance
const modalManager = container.resolve<ClientStateManager.ModalManager>("ModalManager");
const modalState = modalManager.getState();

const { isInitialClickInside } = useCheckMouseInitialClick();

/**
 * @description 모달을 닫는 함수입니다. 이 함수의 실행 시점은 mousedown 이벤트에 따른 콜백함수가 실행된 이후에 실행이 됩니다.
 */
const closeModal = () => {
    // mousedown 이 발생한 위치가 모달의 내부영역이라면, 모달을 닫지 않습니다.
    if (isInitialClickInside.value) {
        return;
    }

    if (modalManager.getState().modalProps?.closeCallback) {
        modalManager.getState().modalProps?.closeCallback();
    }
    modalManager.dispatch("unmountModal");
};
</script>

<template>
    <Teleport to="body" v-if="modalState.modalOpen">
        <div
            class="fixed top-0 left-0 flex items-center justify-center w-full xl:h-full h-lvh bg-primary-black bg-opacity-30 z-999"
            @click.self="closeModal"
        >
            <CommonModalContent />
        </div>
    </Teleport>
</template>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
    transition: all 0.25s ease;
}

.fade-enter-from,
.fade-leave-to {
    transform: translateY(-20px);

    opacity: 0;
}
</style>
