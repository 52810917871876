import { singleton, inject } from "tsyringe";

// adapter
import { ClientStateManager } from "@/adapter/common/stores/client/interface";

@singleton()
export default class CloseAlertDispatcher {
    constructor(
        @inject("AlertManager") private readonly alertManager: ClientStateManager.AlertManager,
        @inject("ModalManager") private readonly modalManager: ClientStateManager.ModalManager,
    ) {}

    public dispatch = () => {
        const isAllClose = this.alertManager.getState().alertProps?.isModalClose;

        this.alertManager.dispatch("unmountAlertModal");

        if (isAllClose) {
            this.modalManager.dispatch("unmountModal");
        }
    };
}
