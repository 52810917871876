import { singleton, inject } from "tsyringe";

// domain
import {
    StudentRepository,
    RequestAcademyStudentsPayload,
} from "@/domain/user/student/repositories/repositories";
import { GetAcademyStudentsResponseDto } from "@/domain/user/student/dtos";

// infrastructure
import HttpAdaptor from "@/infrastructure/libs/http";

@singleton()
export default class StudentRepositoryImpl implements StudentRepository {
    constructor(@inject(HttpAdaptor) private readonly server: HttpAdaptor) {}

    /** @description 챌린지 템플릿 목록 조회 */
    getAcademyStudents = async (payload: RequestAcademyStudentsPayload) => {
        let url = `/v2/academies/${payload.academyId}/students?keyword=${payload.keyword}&size=${payload.size}&include=class&`;

        if (payload.nextToken) {
            url += `nextToken=${payload.nextToken}`;
        }

        const response = await this.server.get<GetAcademyStudentsResponseDto>(url);

        return response;
    };
}
