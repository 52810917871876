import { defineStore } from "pinia";

import type {
    ComponentPropsInModal,
    ModalState,
    ModalLayout,
    ModalInitialState,
} from "@/adapter/common/stores/client/modal/interface";

const useModalStore = defineStore<
    "modalStore",
    ModalState<keyof ComponentPropsInModal, ModalLayout>
>({
    id: "modalStore",
    state: () => ({
        modalOpen: false,
        modalComponent: null,
        modalLayout: null,
        modalProps: null,
        modalInitialState: {} as ModalInitialState,
    }),
    actions: {
        onmountModal<T extends keyof ComponentPropsInModal>(
            component: T,
            props: ComponentPropsInModal[T],
        ) {
            this.modalComponent = component;
            this.modalProps = props ? { ...props } : null;
            this.modalLayout = props ? props.layout : null;
            this.modalOpen = true;
        },
        unmountModal() {
            this.modalOpen = false;
            this.modalComponent = null;
            this.modalLayout = null;
            this.modalProps = null;
        },
        setInitialState<T extends keyof ModalInitialState>(
            component: T,
            initialState: ModalInitialState[T],
        ) {
            this.modalInitialState[component] = initialState;
        },
    },
});

export default useModalStore;
