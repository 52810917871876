import { ref, Ref } from "vue";

// click 을 modal 내부에서 했는지 여부 state
const isInitialClickInside = ref(false);

/**
 * @description mousedown 의 시작이 modal 내부에서라면 마우스를 끌어서 외부에서 mouseup 해도 modal 이 닫히지 않도록 설정하는 hook
 * @param {Ref<HTMLDivElement | null>} ref 참조하고자 하는 element
 */
const useCheckMouseInitialClick = (ref?: Ref<HTMLDivElement | null>) => {
    // 만일 인자로 전달되는 ref 가 존재하지 않을 경우는 isInitialClickInside 만 필요하기에 이것만 반환하고 나머지 함수는 mock
    if (!ref) {
        return {
            isInitialClickInside,
            mount: () => {},
            unmount: () => {},
        };
    }

    /**
     * @description mousedown event : mousedown 의 시작점이 element 내부인지 파악하여, 이에 대한 state 를 변경합니다.
     * @param e Event
     */
    const checkIsMouseClickInside = (e: Event) => {
        const element = ref.value;

        if (element && element.contains(e.target as HTMLDivElement)) {
            isInitialClickInside.value = true;
        } else {
            isInitialClickInside.value = false;
        }
    };

    /**
     * @description mount : mousedown 이벤트를 감지하여 modal 내부에서 click 이벤트가 발생했는지 여부를 확인합니다.
     */
    const mount = () => {
        window.addEventListener("mousedown", checkIsMouseClickInside);
    };

    /**
     * @description unmount : event listener 를 제거합니다.
     */
    const unmount = () => {
        window.removeEventListener("mousedown", checkIsMouseClickInside);
    };

    return {
        isInitialClickInside,
        mount,
        unmount,
    };
};

export default useCheckMouseInitialClick;
