import { defineStore } from "pinia";

import type {
    IStudentChallengesState,
    IStudentChallengesActions,
    IStudentChallengesGetStudentChallengesResponseDto,
} from "./interface";

const useStudentChallengesStore = defineStore<
    "studentChallengesStore",
    IStudentChallengesState,
    {},
    IStudentChallengesActions
>("studentChallengesStore", {
    state: (): IStudentChallengesState => ({
        studentChallenges: null,
        nextToken: null,
    }),
    actions: {
        setStudentChallenges(dto: IStudentChallengesGetStudentChallengesResponseDto) {
            this.studentChallenges = dto;
        },
        setNextToken(nextToken: string) {
            this.nextToken = nextToken;
        },
        resetState() {
            this.studentChallenges = null;
            this.nextToken = null;
        },
    },
});

export default useStudentChallengesStore;
