import { singleton, inject } from "tsyringe";

// domain
import {
    ChallengeTemplateRepository,
    RequestGetChallengeTemplates,
    PostBeforeChallengeTemplatePayload,
} from "@/domain/challenge-template/repositories/repositories";
import {
    GetChallengeTemplatesResponseDto,
    ChallengeTemplateDto,
} from "@/domain/challenge-template/dtos";

// infrastructure
import HttpAdaptor from "@/infrastructure/libs/http";

@singleton()
export default class ChallengeTemplateRepositoryImpl implements ChallengeTemplateRepository {
    constructor(@inject(HttpAdaptor) private readonly server: HttpAdaptor) {}

    /** @description 챌린지 템플릿 목록 조회 */
    getChallengeTemplates = async (request: RequestGetChallengeTemplates) => {
        let url = `/v1/challenge-templates?`;

        url += `academyId=${request.academyId}&`;

        // 챌린지 타입 반복 추가
        request.type.forEach((type) => {
            url += `type=${type}&`;
        });

        if (request.from) {
            url += `from=${request.from}&`;
        }

        if (request.to) {
            url += `to=${request.to}&`;
        }

        url += `keyword=${request.keyword}&`;
        url += `size=${request.size}&`;
        url += `order=${request.order}&`;

        if (request.nextToken) {
            url += `nextToken=${request.nextToken}`;
        }

        const response = await this.server.get<GetChallengeTemplatesResponseDto>(url);

        return response;
    };

    /** @description 챌린지 템플릿 삭제 */
    deleteChallengeTemplate = async (challengeTemplateId: string) => {
        await this.server.delete(`/v1/challenge-templates/${challengeTemplateId}`);
    };

    /**
     * @description 비포챌린지 템플릿 생성
     * @param {PostBeforeChallengeTemplatePayload} payload - 비포챌린지 템플릿 생성 요청 데이터
     */
    postBeforeChallengeTemplate = async (payload: PostBeforeChallengeTemplatePayload) => {
        const response = await this.server.post<ChallengeTemplateDto>(
            "/v1/challenge-templates",
            payload,
        );

        return response;
    };
}
