import { injectable, inject } from "tsyringe";

// adapter
import { ClientStateManager } from "@/adapter/common/stores/client/interface";

// ui
import type { NavigationBarProps } from "@/ui/shared/components/navigation/navigationBar/NavigationbarModel";

@injectable()
export class NavigationBar {
    private _props: NavigationBarProps;

    constructor(
        props: NavigationBarProps,
        @inject("ModalManager") private modalManager: ClientStateManager.ModalManager,
    ) {
        this._props = props;
    }

    get props(): NavigationBarProps {
        return this._props;
    }

    popupModal = () => {
        switch (this._props.value) {
            case "로그아웃": {
                this.modalManager.dispatch("onmountModal", [
                    "confirm-logout",
                    {
                        title: "정말 로그아웃 하시겠습니까?",
                        subTitle: "다음에 또 봐요!",
                        layout: "AlertWithSub",
                    },
                ]);
                return;
            }
            case "좌석 설정": {
                this.modalManager.dispatch("onmountModal", [
                    "setting-academy-seat",
                    {
                        title: "좌석 설정",
                        layout: "ModalBaseTitle",
                    },
                ]);
                return;
            }
            default:
                return;
        }
    };
}

@injectable()
export class NavigationBarFactory {
    constructor(@inject("ModalManager") private modalManager: ClientStateManager.ModalManager) {}

    create(props: NavigationBarProps) {
        return new NavigationBar(props, this.modalManager);
    }
}
