import { defineStore } from "pinia";

import type { ICandidateManagerState, IAcademyCandidateDto } from "./interface";

const useCandidateStore = defineStore("candidateStore", {
    state: (): ICandidateManagerState => ({
        candidateStudentDto: null,
    }),
    getters: {
        getCandidateId: (state) => {
            return state.candidateStudentDto?.id;
        },
    },
    actions: {
        setAcademyCandidateDto(responseDto: IAcademyCandidateDto) {
            this.candidateStudentDto = responseDto;
        },
        resetState() {
            this.candidateStudentDto = null;
        },
    },
});

export default useCandidateStore;
