import { defineStore } from "pinia";

import { studyItemTypesSet, referenceStudyItemTypes } from "./repository";
import type {
    CurriculumClientState,
    PlannedCurriculumRoadmap,
    SimplifiedCurriculumValue,
    CurriculumMetadataSet,
    CurriculumMetadataTable,
} from "@/adapter/common/stores/client/curriculum/interface";

const useCurriculumClientStore = defineStore("curriculumClientStore", {
    state: (): CurriculumClientState => ({
        /** 완료된 커리큘럼의 존재 여부 (화면상 미리보기를 띄울지 결정) */
        completedGetPreviewPlannedCurriculum: false,
        curriculumId: "",
        name: "",
        nameValidateMessage: "",
        customMode: false,
        policy: 2,
        middleUnitIds: [],
        isCallabled: null,
        timeDifficulties: [],
        timeDifficultyMode: 4,
        timedifficultiesSet: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
        referenceTimeDifficulties: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
        studyItemTypes: [],
        studyItemTypesSet: studyItemTypesSet,
        referenceStudyItemTypes: referenceStudyItemTypes,
        plannedCurriculumRoadmap: null,
        /** 커리큘럼 리스트 */
        currentCurriculumMetadataList: [],
        completedCurriculumMetadataList: [],
        completedCurriculumMetadataTable: {
            classCurriculumTableHeader: [],
            classCurriculumTableBody: [],
        },
        currentCurriculumMetadataTable: {
            classCurriculumTableHeader: [],
            classCurriculumTableBody: [],
        },
        currentCurriculumSetList: null,
    }),
    getters: {
        checkCurriculumMetadatas: (state) => {
            return (
                state.name !== "" &&
                state.nameValidateMessage === "" &&
                state.middleUnitIds.length > 0 &&
                state.isCallabled !== null &&
                state.timeDifficulties.length > 0 &&
                state.studyItemTypes.length > 0 &&
                (state.policy === 0 || state.policy === 1)
            );
        },
        convertedCallabled: (state) => {
            return state.isCallabled ? "호출" : "질문";
        },
    },
    actions: {
        setCompletedGetPreviewPlannedCurriculum(completed: boolean) {
            this.completedGetPreviewPlannedCurriculum = completed;
        },
        setCurriculumId(curriculumId: string) {
            this.curriculumId = curriculumId;
        },
        setName(name: string) {
            this.name = name;
        },
        setNameValidateMessage(nameValidateMessage: string) {
            this.nameValidateMessage = nameValidateMessage;
        },
        setCustomMode(customMode: boolean) {
            this.customMode = customMode;
        },
        setPolicy(policy: number) {
            this.policy = policy;
        },
        setMiddleUnitIds(middleUnitIds: number[]) {
            this.middleUnitIds = middleUnitIds;
        },
        setCallabled(isCallabled: boolean) {
            this.isCallabled = isCallabled;
        },
        setTimeDifficulties(timeDifficulties: number[]) {
            this.timeDifficulties = timeDifficulties;
        },
        setTimeDifficultyMode(timeDifficultyMode: number) {
            this.timeDifficultyMode = timeDifficultyMode;
        },
        setStudyItemTypes(studyItemTypes: number[]) {
            this.studyItemTypes = studyItemTypes;
        },
        setPlannedCurriculumRoadmap(curriculumRoadmap: PlannedCurriculumRoadmap) {
            this.plannedCurriculumRoadmap = curriculumRoadmap;
        },
        setCurrentCurriculumMetadataList(
            currentCurriculumMetadataList: SimplifiedCurriculumValue[],
        ) {
            if (this.currentCurriculumMetadataList instanceof Array) {
                this.currentCurriculumMetadataList = this.currentCurriculumMetadataList.concat(
                    currentCurriculumMetadataList,
                );
                return;
            }

            this.currentCurriculumMetadataList = currentCurriculumMetadataList;
        },
        setCompletedCurriculumMetadataList(
            completedCurriculumMetadataList: SimplifiedCurriculumValue[],
        ) {
            if (this.completedCurriculumMetadataList instanceof Array) {
                this.completedCurriculumMetadataList = this.completedCurriculumMetadataList.concat(
                    completedCurriculumMetadataList,
                );
                return;
            }

            this.completedCurriculumMetadataList = completedCurriculumMetadataList;
        },

        /** table */
        setCompletedCurriculumMetadataTable(
            completedCurriculumMetadataTable: CurriculumMetadataTable,
        ) {
            this.completedCurriculumMetadataTable = completedCurriculumMetadataTable;
        },
        setCurrentCurriculumMetadataTable(currentCurriculumMetadataTable: CurriculumMetadataTable) {
            this.currentCurriculumMetadataTable = currentCurriculumMetadataTable;
        },

        /** current curriculum */

        setCurrentCurriculumSetList(currentCurriculumSetList: CurriculumMetadataSet[]) {
            this.currentCurriculumSetList = currentCurriculumSetList;
        },

        resetCurrentCurriculumList() {
            this.currentCurriculumMetadataList = [];
        },

        resetCompletedCurriculumList() {
            this.completedCurriculumMetadataList = [];
        },

        resetState() {
            this.completedGetPreviewPlannedCurriculum = false;
            this.curriculumId = "";
            this.name = "";
            this.customMode = false;
            this.policy = 2;
            this.middleUnitIds = [];
            this.isCallabled = null;
            this.timeDifficulties = [];
            this.timeDifficultyMode = 4;
            this.studyItemTypes = [];
        },
    },
});

export default useCurriculumClientStore;
