import { singleton, inject } from "tsyringe";

// domain
import {
    SubmittedProblemRepository,
    RequestGetSubmittedProblemsPayload,
} from "@/domain/user/student/submitted-problems/repositories/repositories";
import { GetStudentSubmittedProblemsResponseDto } from "@/domain/user/student/submitted-problems/dtos";

// infrastructure
import HttpAdaptor from "@/infrastructure/libs/http";

@singleton()
export default class SubmittedProblemsRepositoryImpl implements SubmittedProblemRepository {
    constructor(@inject(HttpAdaptor) private readonly server: HttpAdaptor) {}

    /** @description 챌린지 템플릿 목록 조회 */
    getStudentSubmittedProblems = async (request: RequestGetSubmittedProblemsPayload) => {
        let url = `/v1/student-submitted-problems?`;

        if (request.keyword) {
            url += `keyword=${request.keyword}&`;
        }

        if (request.studentId) {
            url += `studentId=${request.studentId}&`;
        }

        if (request.academyId) {
            url += `academyId=${request.academyId}&`;
        }

        if (request.classId) {
            url += `classId=${request.classId}&`;
        }

        if (request.semesterId) {
            if (Array.isArray(request.semesterId)) {
                request.semesterId.forEach((semesterId) => {
                    url += `semesterId=${semesterId}&`;
                });
            } else {
                url += `semesterId=${request.semesterId}&`;
            }
        }

        if (request.from) {
            url += `from=${request.from}&`;
        }

        if (request.to) {
            url += `to=${request.to}&`;
        }

        if ("fromWrongCount" in request) {
            url += `fromWrongCount=${request.fromWrongCount}&`;
        }

        if ("toWrongCount" in request) {
            url += `toWrongCount=${request.toWrongCount}&`;
        }

        url += `size=${request.size}&`;

        if (request.nextToken) {
            url += `nextToken=${request.nextToken}`;
        }

        const response = await this.server.get<GetStudentSubmittedProblemsResponseDto>(url);

        return response;
    };
}
