import { container } from "tsyringe";
import { createRouter, createWebHistory } from "vue-router";

// application
import { ApplicationServerStateManager } from "@/application/common/interface";

/** layout */
import AuthLayout from "@/ui/shared/layouts/AuthLayout.vue";
import DefaultLayout from "@/ui/shared/layouts/DefaultLayout.vue";
import NotFoundLayout from "@/ui/shared/layouts/NotFoundLayout.vue";

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: [
        {
            path: "/",
            redirect: "/auth",
        },
        {
            path: "/auth",
            name: "auth",
            component: () => import("@/ui/views/auth/IndexPage.vue"),
            meta: {
                requiresAuth: false,
                layout: AuthLayout,
            },
        },
        {
            path: "/home",
            name: "home",
            component: () => import("@/ui/views/student-setting/IndexPage.vue"),
            meta: {
                requiresAuth: true,
                layout: DefaultLayout,
            },
        },
        {
            path: "/student-setting/:id?",
            name: "student-setting",
            component: () => import("@/ui/views/student-setting/IndexPage.vue"),
            meta: {
                requiresAuth: true,
                layout: DefaultLayout,
            },
        },
        {
            path: "/student-management",
            name: "student-management",
            component: () => import("@/ui/views/student-management/IndexPage.vue"),
            meta: {
                requiresAuth: true,
                layout: DefaultLayout,
            },
        },
        {
            path: "/statistical-management",
            name: "statistical-management",
            component: () => import("@/ui/views/statistical-management/IndexPage.vue"),
            meta: {
                requiresAuth: true,
                layout: DefaultLayout,
            },
        },
        {
            path: "/teacher-management",
            name: "teacher-management",
            component: () => import("@/ui/views/teacher-management/IndexPage.vue"),
            meta: {
                requiresAuth: true,
                layout: DefaultLayout,
            },
        },
        {
            path: "/class-management",
            name: "class-management",
            component: () => import("@/ui/views/class-management/IndexPage.vue"),
            meta: {
                requiresAuth: true,
                layout: DefaultLayout,
            },
        },
        {
            path: "/challenge-up",
            name: "challenge-up",
            component: () => import("@/ui/views/challenge-up/IndexPage.vue"),
            children: [
                {
                    path: "before-challenge-form",
                    name: "before-challenge-form",
                    component: () =>
                        import("@/ui/views/challenge-up/before-challenge-form/IndexPage.vue"),
                    meta: {
                        requiresAuth: true,
                        layout: DefaultLayout,
                    },
                },
                {
                    path: "after-challenge-form",
                    name: "after-challenge-form",
                    component: () =>
                        import("@/ui/views/challenge-up/after-challenge-form/IndexPage.vue"),
                    meta: {
                        requiresAuth: true,
                        layout: DefaultLayout,
                    },
                },
            ],
            meta: {
                requiresAuth: true,
                layout: DefaultLayout,
            },
        },
        {
            path: "/business-management",
            name: "business-management",
            component: () => import("@/ui/views/business-management/IndexPage.vue"),
            meta: {
                requiresAuth: true,
                layout: DefaultLayout,
            },
        },
        {
            path: "/message-management",
            name: "message-management",
            component: () => import("@/ui/views/message-management/IndexPage.vue"),
            meta: {
                requiresAuth: true,
                layout: DefaultLayout,
            },
        },
        {
            path: "/customer-center",
            name: "customer-center",
            component: () => import("@/ui/views/customer-center/IndexPage.vue"),
            meta: {
                requiresAuth: true,
                layout: DefaultLayout,
            },
        },
        {
            path: "/setting",
            name: "setting",
            component: () => import("@/ui/views/setting/IndexPage.vue"),
            meta: {
                requiresAuth: true,
                layout: DefaultLayout,
            },
        },
        {
            path: "/test",
            name: "test",
            component: () => import("@/ui/views/componentTest/IndexPage.vue"),
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: "/:pathMatch(.*)*",
            name: "NotFound",
            component: () => import("@/ui/views/404.vue"),
            meta: {
                requiresAuth: false,
                layout: NotFoundLayout,
            },
        },
    ],
});

/** router가 작동하기 전에 accessToken 을 감지합니다. */
router.beforeEach((to, from, next) => {
    const authManager = container.resolve<ApplicationServerStateManager.AuthManager>("AuthManager");
    const accesToken = authManager.getState().userAccessToken;
    if (!accesToken) {
        if (to.meta.requiresAuth) {
            next("/auth");
        } else {
            next();
        }
    } else {
        if (to.name === "auth") {
            next("/home");
        } else {
            next();
        }
    }
});

export default router;
