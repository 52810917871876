import { defineStore } from "pinia";

import type {
    IChallengeTemplateState,
    IChallengeTemplateActions,
    IGetChallengeTemplatesResponseDto,
} from "./interface";

const useChallengesTemplateStore = defineStore<
    "challengesTemplateStore",
    IChallengeTemplateState,
    {},
    IChallengeTemplateActions
>("challengesTemplateStore", {
    state: (): IChallengeTemplateState => ({
        challengeTemplateDtos: null,
        nextToken: null,
    }),
    actions: {
        setChallengeTemplateDto(dto: IGetChallengeTemplatesResponseDto) {
            this.challengeTemplateDtos = dto;
        },
        setNextToken(nextToken: string) {
            this.nextToken = nextToken;
        },
        resetState() {
            this.challengeTemplateDtos = null;
            this.nextToken = null;
        },
    },
});

export default useChallengesTemplateStore;
