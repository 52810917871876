import { container } from "tsyringe";

// domain
import { ChallengeTemplateRepository } from "@/domain/challenge-template/repositories/repositories";
import { ChallengeRepository } from "@/domain/challenge/repositories/repositories";
import { StudentChallengeRepository } from "@/domain/academy/student-challenge/repositories/repositories";
import { SubmittedProblemRepository } from "@/domain/user/student/submitted-problems/repositories/repositories";
import { StudentRepository } from "@/domain/user/student/repositories/repositories";

// infrastructure
import ChallengeTemplateRepositoryImpl from "@/infrastructure/repositoriesImpl/challenge-template/ChallengeTemplateRepositoryImpl";
import ChallengeRepositoryImpl from "@/infrastructure/repositoriesImpl/challenge/ChallengeRepositoryImpl";
import StudentChallengesRepositoryImpl from "@/infrastructure/repositoriesImpl/academy/student-challenges/StudentChallengesRepositoryImpl";
import SubmittedProblemsRepositoryImpl from "@/infrastructure/repositoriesImpl/user/student/submitted-problems/SubmittedProblemsRepositoryImpl";
import StudentRepositoryImpl from "@/infrastructure/repositoriesImpl/user/student/StudentRepositoryImpl";

const repositoriesRegisterInit = () => {
    container.register<ChallengeTemplateRepository>("ChallengeTemplateRepository", {
        useClass: ChallengeTemplateRepositoryImpl,
    });
    container.register<ChallengeRepository>("ChallengeRepository", {
        useClass: ChallengeRepositoryImpl,
    });
    container.register<StudentChallengeRepository>("StudentChallengeRepository", {
        useClass: StudentChallengesRepositoryImpl,
    });
    container.register<SubmittedProblemRepository>("SubmittedProblemRepository", {
        useClass: SubmittedProblemsRepositoryImpl,
    });
    container.register<StudentRepository>("StudentRepository", {
        useClass: StudentRepositoryImpl,
    });
};

export default repositoriesRegisterInit;
