<script setup lang="ts">
import { computed } from "vue";
import { CommonAlert, CommonModal, CommonModalSecondLayer } from "@/ui/shared/components";
import { VueQueryDevtools } from "@tanstack/vue-query-devtools";

const envDevelopment = computed(() => {
    return import.meta.env.DEV;
});
</script>

<template>
    <section class="flex items-center justify-center w-[138rem] xl:w-full">
        <section class="xl:w-full w-[160rem] h-dvh">
            <component :is="$route.meta.layout">
                <router-view></router-view>
            </component>
            <CommonAlert />
            <CommonModal />
            <CommonModalSecondLayer />
        </section>
    </section>
</template>
