import axios from "axios";
import { REFRESH_URL } from "@/infrastructure/libs/http/axiosFetcher";

export interface RefreshTokenResponse {
    accessToken: string;
    refreshToken: string;
}

export class AccessTokenRefresher {
    promise: Promise<string> | undefined;
    baseURL: string;

    constructor() {
        this.promise = undefined;
        this.baseURL = import.meta.env.VITE_API_URL;
    }

    public refresh = async (userRefreshToken: string): Promise<string> => {
        /** refresh 요청 중이라면 */
        if (this.promise) return this.promise;

        /** accessToken 을 다시 반환
         * 테스트 : import.meta.env.VITE_DOCKER_API_URL
         */
        this.promise = (async () => {
            try {
                const responseToken = await axios.post<RefreshTokenResponse>(
                    `${this.baseURL}${REFRESH_URL}`,
                    {
                        refreshToken: userRefreshToken,
                    },
                );
                /** 생성된지 7일이 지났다면 */
                if (responseToken.data.refreshToken !== undefined) {
                    localStorage.setItem("userRefreshToken", responseToken.data.refreshToken);
                }
                localStorage.setItem("userAccessToken", responseToken.data.accessToken);

                this.promise = undefined;

                return responseToken.data.accessToken;
            } catch (error) {
                this.promise = undefined;
                return "";
            }
        })();

        return this.promise;
    };
}
