import { defineStore } from "pinia";

import type { IAuthState, IPrincipalDto } from "./interface";
import { IUserType } from "./interface";

const useAuthStore = defineStore<"authStore", IAuthState>({
    id: "authStore",
    state: () => ({
        userDto: null,
        userEmail: localStorage.getItem("userEmail") || "",
        academyId: localStorage.getItem("academyId") || "",
        teacherId: localStorage.getItem("teacherId") || "",
        userType: 0,
        userAccessToken: localStorage.getItem("userAccessToken") || null,
        userRefreshToken: localStorage.getItem("userRefreshToken") || null,
    }),
    getters: {
        getAcademyName() {
            const isPrincipal = this.userDto && this.userDto.type === IUserType.PrincipalTeacher;

            return isPrincipal
                ? (this.userDto as IPrincipalDto).academy?.name
                : "수학에 심장을 달다";
        },
    },
    actions: {
        resetState() {
            this.userDto = null;
            this.userEmail = "";
            this.academyId = "";
            this.teacherId = "";
            this.userType = 0;
            this.userAccessToken = null;
            this.userRefreshToken = null;
        },
        setUserEmail(userEmail: string) {
            this.userEmail = userEmail;
            localStorage.setItem("userEmail", userEmail);
        },
    },
    persist: true,
});

export default useAuthStore;
