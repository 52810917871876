import { defineStore } from "pinia";

import type {
    ICurriculumMetadatasState,
    IPlannedCurriculumDto,
    IUserCurriculumMetadatas,
    ISelectedCurriculumMetadata,
} from "@/infrastructure/stores/server/curriculum-metadatas/interface";

const useCurriculumMetadatasStore = defineStore("curriculumMetadatasStore", {
    state: (): ICurriculumMetadatasState => ({
        plannedCurriculumMetadata: null,
        userCurriculumMetadatas: null,
        lastCurriculumNextToken: null,
        userCompletedCurriculumMetadatas: null,
        lastCompletedCurriculumNextToken: null,
        totalCurriculumMetadatas: null,
        lastTotalArrayCurriculumNextToken: null,
        selectedCompletedCurriculumMetadata: null,
        selectedCurriculumMetadata: null,
    }),
    getters: {
        getPlannedTotalProblemCounts: (state): number => {
            if (!state.plannedCurriculumMetadata) return 0;

            return state.plannedCurriculumMetadata.reduce(
                (initialCount, metadata) => (initialCount += metadata.problemCount),
                0,
            );
        },
        getPlannedTotalEstimatedTime: (state): number => {
            if (!state.plannedCurriculumMetadata) return 0;

            return state.plannedCurriculumMetadata.reduce(
                (initialTime, metadata) => (initialTime += metadata.estimatedTime),
                0,
            );
        },
    },
    actions: {
        setPlannedCurriculumMetadata(responseDto: IPlannedCurriculumDto[]) {
            this.plannedCurriculumMetadata = responseDto;
        },
        setUserCurriculumMetadatas(responseDto: IUserCurriculumMetadatas) {
            if (this.userCurriculumMetadatas instanceof Array) {
                this.userCurriculumMetadatas = this.userCurriculumMetadatas.concat(responseDto);
                return;
            }

            this.userCurriculumMetadatas = responseDto;
        },
        setLastCurriculumNextToken(nextToken: string) {
            this.lastCurriculumNextToken = nextToken;
        },
        setUserCompletedCurriculumMetadatas(responseDto: IUserCurriculumMetadatas) {
            if (this.userCompletedCurriculumMetadatas instanceof Array) {
                this.userCompletedCurriculumMetadatas =
                    this.userCompletedCurriculumMetadatas.concat(responseDto);
                return;
            }

            this.userCompletedCurriculumMetadatas = responseDto;
        },
        setLastCompletedCurriculumNextToken(nextToken: string) {
            this.lastCompletedCurriculumNextToken = nextToken;
        },
        setTotalCurriculumMetadatas(responseDto: IUserCurriculumMetadatas) {
            if (this.totalCurriculumMetadatas instanceof Array) {
                this.totalCurriculumMetadatas = this.totalCurriculumMetadatas.concat(responseDto);
                return;
            }

            this.totalCurriculumMetadatas = responseDto;
        },
        setLasteTotalArrayCurriculumNextToken(nextToken: string) {
            this.lastTotalArrayCurriculumNextToken = nextToken;
        },

        setSelectedCurriculumMetadata(responseDto: ISelectedCurriculumMetadata) {
            this.selectedCurriculumMetadata = responseDto;
        },
        setSelectedCompletedCurriculumMetadata(responseDto: ISelectedCurriculumMetadata) {
            this.selectedCompletedCurriculumMetadata = responseDto;
        },
        resetTotalCurriculumMetadatas() {
            this.totalCurriculumMetadatas = null;
            this.lastTotalArrayCurriculumNextToken = null;
        },
        resetCurrentCurriculumMetadatas() {
            this.userCurriculumMetadatas = null;
        },
        resetCompletedCurriculumMetadatas() {
            this.userCompletedCurriculumMetadatas = null;
        },
        resetState() {
            this.plannedCurriculumMetadata = null;
            this.userCurriculumMetadatas = null;
            this.lastCurriculumNextToken = null;
            this.userCompletedCurriculumMetadatas = null;
            this.lastCompletedCurriculumNextToken = null;
            this.totalCurriculumMetadatas = null;
            this.lastTotalArrayCurriculumNextToken = null;
        },
    },
});

export default useCurriculumMetadatasStore;
