<script setup lang="ts"></script>

<template>
    <section class="flex items-center justify-center w-full bg-white h-lvh">
        <div class="flex flex-col justify-center items-center w-full h-full gap-[4rem]">
            <slot></slot>
        </div>
    </section>
</template>

<style lang="scss" scoped></style>
