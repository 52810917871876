import { defineStore } from "pinia";

import type { IMiddleUnitsManagerState, IMiddleUnitDto } from "./interface";

const useMiddleUnitsStore = defineStore("middleUnitsStore", {
    state: (): IMiddleUnitsManagerState => ({
        middleUnitsDto: null,
    }),
    actions: {
        setMiddleUnitsDto(responseDto: IMiddleUnitDto[]) {
            this.middleUnitsDto = responseDto;
        },
        resetState() {
            this.middleUnitsDto = null;
        },
    },
    persist: true,
});

export default useMiddleUnitsStore;
