import { defineStore } from "pinia";

import type { ILocationsManagerState, ILocationsDto, ILocationConvetedOptions } from "./interface";

const useLocationsStore = defineStore("locationsStore", {
    state: (): ILocationsManagerState => ({
        locationsDto: null,
    }),
    getters: {
        getLocationsOptions: (state): ILocationConvetedOptions[] => {
            if (!state.locationsDto) return [];
            return state.locationsDto.map((location) => {
                return {
                    value: location.id,
                    label: location.name,
                };
            });
        },
    },
    actions: {
        setLocationsDto(responseDto: ILocationsDto) {
            this.locationsDto = responseDto;
        },
        resetState() {
            this.locationsDto = null;
        },
    },
    persist: true,
});

export default useLocationsStore;
