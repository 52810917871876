import { defineStore } from "pinia";

import type { IAcademyClassesState, IAcademyClassDto } from "./interface";

const useAcademyClassesStore = defineStore("academyClassesStore", {
    state: (): IAcademyClassesState => ({
        academyClasses: null,
        selectedClass: null,
        totalCounts: 0,
        nextToken: null,
    }),
    actions: {
        setAcademyClassesDto(responseDto: IAcademyClassDto[]) {
            this.academyClasses = responseDto;
        },
        setSelectedClass(selectedClass: IAcademyClassDto) {
            this.selectedClass = selectedClass;
        },
        setTotalCounts(totalCounts: number) {
            this.totalCounts = totalCounts;
        },
        setNextToken(nextToken: string) {
            this.nextToken = nextToken;
        },
        resetState() {
            this.academyClasses = null;
            this.selectedClass = null;
            this.totalCounts = 0;
            this.nextToken = null;
        },
    },
});

export default useAcademyClassesStore;
