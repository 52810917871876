<script setup lang="ts">
import { ref, defineAsyncComponent, computed } from "vue";
import { container } from "tsyringe";

// adapter
import { ClientStateManager } from "@/adapter/common/stores/client/interface";
import CloseAlertDispatcher from "@/adapter/alert/workers/common/CloseAlertDispatcher";

// ui
import RootErrorBoundary from "@/ui/shared/error/RootErrorBoundary.vue";

// instance
const closeAlertDispatcher = container.resolve(CloseAlertDispatcher);
const alertManager = container.resolve<ClientStateManager.AlertManager>("AlertManager");
const alertState = alertManager.getState();

type AlertComponent = typeof alertState.alertComponent | null;

const alertComponent = ref<AlertComponent>(null);
alertComponent.value = alertState.alertComponent;

const component = computed(() => {
    const alertComponentPath = alertState.alertComponent;
    return defineAsyncComponent(() => import(`../alerts/${alertComponentPath}.vue`));
});

const closeAlertModal = () => {
    const isStrong = alertState.alertProps?.isStrong;

    // 만일 외부에서 닫히면 안되는 알림창이라면 그대로 return 합니다.
    if (isStrong) {
        return;
    }
    closeAlertDispatcher.dispatch();
};
</script>

<template>
    <Teleport to="body" v-if="alertState.alertOpen">
        <div
            class="fixed top-0 left-0 flex items-center justify-center w-full xl:h-full h-lvh bg-primary-black bg-opacity-30 z-999"
            @click.self="closeAlertModal"
        >
            <div class="flex items-center justify-center h-auto w-fit z-1000" tabindex="-1">
                <RootErrorBoundary>
                    <Transition name="fade">
                        <component :is="component" />
                    </Transition>
                </RootErrorBoundary>
            </div>
        </div>
    </Teleport>
</template>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
    transition: all 0.25s ease;
}

.fade-enter-from,
.fade-leave-to {
    transform: translateY(-20px);

    opacity: 0;
}
</style>
