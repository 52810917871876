import { defineStore } from "pinia";

import type { IChallengesClientState, IChallengesClientActions } from "./interface";

const useChallengesClientStore = defineStore<
    "challengesClientStore",
    IChallengesClientState,
    {},
    IChallengesClientActions
>("challengesClientStore", {
    state: (): IChallengesClientState => ({
        challengeId: "",
        deleteChallengeTemplateId: null,
        challengeMainPageEnter: false,
        challengeProvidePageFirstMount: false,
    }),
    actions: {
        controllCurrentChallenge(challengeId: string) {
            this.challengeId = challengeId;
        },
        resetState() {
            this.challengeId = "";
            this.deleteChallengeTemplateId = null;
            this.challengeMainPageEnter = false;
            this.challengeProvidePageFirstMount = false;
        },
        resetSelectedTemplate() {
            this.deleteChallengeTemplateId = null;
        },
        notifyDeleteChallengeTemplate(challengeTemplateId: string) {
            this.deleteChallengeTemplateId = challengeTemplateId;
        },
        enterChallengeMainPage() {
            this.challengeMainPageEnter = true;
            this.challengeProvidePageFirstMount = true;
        },
        leaveChallengeMainPage() {
            this.challengeMainPageEnter = false;
            this.challengeProvidePageFirstMount = false;
        },
        afterMountChallengeProvidePage() {
            this.challengeProvidePageFirstMount = false;
        },
    },
});

export default useChallengesClientStore;
